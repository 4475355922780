// prompts.js

import React, { useContext, useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { ThemeContext } from './ThemeContext';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import anime from 'animejs/lib/anime.es.js';

export const prompts = [
  {
    text: 'Get to know Athena',
    image: (
      <Logo
        style={{
          marginTop: '0',
          marginLeft: '0',
          width: '20vh',
          height: '20vh',
        }}
      />
    ),
  },
  {
    text: 'Setup profile',
    image: (
      <Logo
        style={{
          marginTop: '0vh',
          marginLeft: '0',
          width: '20vh',
          height: '20vh',
        }}
      />
    ),
  },
  {
    text: 'What size',
    image: (
      <Logo
        style={{
          marginTop: '0vh',
          marginLeft: '0',
          width: '20vh',
          height: '20vh',
        }}
      />
    ),
  },
  {
    text: 'Recent Drops',
    image: (
      <Logo
        style={{
          marginTop: '0vh',
          marginLeft: '0',
          width: '20vh',
          height: '20vh',
        }}
      />
    ),
  },
  {
    text: 'Explain closures',
    image: (
      <Logo
        style={{
          marginTop: '0vh',
          marginLeft: '0',
          width: '20vh',
          height: '20vh',
        }}
      />
    ),
  },
];

// Custom Previous Arrow Component
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        left: '10vw',
        zIndex: 1000,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <MinusIcon style={{ fill: 'currentColor', width: '4.5vh', height: '4.5vh' }} />
    </div>
  );
};

// Custom Next Arrow Component
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        right: '10vw',
        zIndex: 1000,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <PlusIcon style={{ fill: 'currentColor', width: '4.5vh', height: '4.5vh' }} />
    </div>
  );
};

export const PromptsList = ({ sendMessage, userMessageCount }) => {
  const {
    primaryColor,
    textColor,
    isDarkMode,
    isOptionsOpen,
    isNewButtonClicked,
    toggleNewButton,
    clickedOnce,
    inputWidthLeft, // Added to use the new global variable
  } = useContext(ThemeContext); // Destructure inputWidthLeft from ThemeContext

  const [currentSlide, setCurrentSlide] = useState(0);
  const [showPrompts, setShowPrompts] = useState(true);
  const sliderRef = useRef(null);

  const handlePromptClick = async (promptText) => {
    sendMessage(promptText);
    setShowPrompts(false);
    console.log(`Prompt clicked: ${promptText}`);
  };

  // Adjust slider settings based on isNewButtonClicked
  const slidesToShow = isNewButtonClicked ? 6 : 3; // Show 6 slides when isNewButtonClicked is true
  const totalPrompts = isNewButtonClicked ? 6 : prompts.length;

  // Generate the prompts array based on totalPrompts
  const promptsToUse = Array.from({ length: totalPrompts }, (_, i) => {
    // Use existing prompts if available, else create new ones
    if (i < prompts.length) {
      return prompts[i];
    } else {
      return {
        text: `Prompt ${i + 1}`,
        image: (
          <Logo
            style={{
              marginTop: '0vh',
              marginLeft: '0',
              width: '20vh',
              height: '20vh',
            }}
          />
        ),
      };
    }
  });

  // Define slider settings to keep the center slide and animations in sync
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow, // Adjust slidesToShow based on isNewButtonClicked
    slidesToScroll: 1,
    centerMode: true, // Enable centerMode for centering the middle slide
    centerPadding: '0px',
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    swipe: true,
    draggable: true,
    afterChange: (index) => setCurrentSlide(index),
    initialSlide: isNewButtonClicked ? 4 : Math.floor(totalPrompts / 2), // Set initialSlide to 4 when isNewButtonClicked is true
  };

  // Function to apply animation on the center slide item
  const applyAnimations = () => {
    // Reset scale for all slides
    anime({
      targets: '.slick-slide',
      scale: 0.85,
      duration: 300,
      easing: 'easeOutQuad',
    });
    // Highlight the center slide with a larger scale
    anime({
      targets: '.slick-center',
      scale: 1.2,
      duration: 500,
      easing: 'easeOutQuad',
    });
    console.log('Scaling animation applied to slider items');
  };

  // Apply animations when the component mounts or the current slide changes
  useEffect(() => {
    applyAnimations(); // Apply initial animation
    const interval = setInterval(() => {
      applyAnimations();
    }, 300); // Reapply every 300ms for consistent updates

    return () => clearInterval(interval);
  }, [currentSlide]);

  // Logic to determine if prompts should be shown
  const shouldShowPrompts =
    showPrompts &&
    !clickedOnce &&
    (userMessageCount === 0 ||
      (userMessageCount > 0 &&
        ((isOptionsOpen && isNewButtonClicked) ||
          (!isOptionsOpen && !isNewButtonClicked) ||
          (isOptionsOpen && !isNewButtonClicked))));

  // Detailed logging of each condition affecting prompt display
  console.log(`Conditions for Prompt Display:`);
  console.log(`- showPrompts: ${showPrompts}`);
  console.log(`- clickedOnce: ${clickedOnce}`);
  console.log(`- userMessageCount: ${userMessageCount}`);
  console.log(`- isOptionsOpen: ${isOptionsOpen}`);
  console.log(`- isNewButtonClicked: ${isNewButtonClicked}`);
  console.log(`- shouldShowPrompts: ${shouldShowPrompts}`);
  console.log(`- primaryColor: ${primaryColor}`);
  console.log(`- textColor: ${textColor}`);
  console.log(`- isDarkMode: ${isDarkMode}`);
  console.log(`Rendering slider: ${shouldShowPrompts ? 'Yes' : 'No'}`);

  return (
    <>
      {shouldShowPrompts && (
        <div
          style={{
            marginLeft: isNewButtonClicked
              ? `calc(-12.5vw + 25px + ${inputWidthLeft}px)` // Updated to use inputWidthLeft
              : '0',
            marginRight: isNewButtonClicked
              ? `calc(-12.5vw -225px + ${inputWidthLeft}px)` // Updated to use inputWidthLeft
              : '0',
            transition: 'margin-left 0.3s ease',
            position: 'relative', // Ensure the prompts are positioned correctly
            zIndex: 1001, // Higher z-index to appear above other chat elements
            marginBottom: '10px', // Add some space below the prompts
            marginTop: '25%',
          }}
        >
          <Slider {...sliderSettings} ref={sliderRef}>
            {promptsToUse.map((prompt, index) => (
              <div
                key={index}
                className="slick-slide"
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginRight: '2vh',
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <div
                  style={{
                    marginBottom: '1vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {React.cloneElement(prompt.image, {
                    style: {
                      ...prompt.image.props.style,
                    },
                  })}
                </div>
                <button
                  className="prompt-box"
                  onClick={() => handlePromptClick(prompt.text)}
                  style={{
                    marginLeft: '4vh',
                    //marginTop: '25%',
                    width: '15vh',
                    height: '10vh',
                    padding: '1vh',
                    borderRadius: '1vh',
                    backgroundColor: primaryColor,
                    color: textColor,
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '2vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {prompt.text}
                </button>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};
