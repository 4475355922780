import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from './ThemeContext.js';
import { ReactComponent as SendIcon } from './send.svg';
import './SendButton.css';

const SendButton = ({ userInput, fontSize, onSvgReRender, sendMessage }) => {
    const { primaryColor, isDarkMode, notifyClick, isNewButtonClicked } = useContext(ThemeContext);
    const sendButtonRef = useRef(null);
    const [iconColor, setIconColor] = useState(isDarkMode ? '#3c3c3e' : '#f0f0f5');

    // Adjust background color based on isDarkMode and input presence
    const adjustColor = (color, percent) => {
        const num = parseInt(color.slice(1), 16),
              amt = Math.round(2.55 * percent),
              R = (num >> 16) + amt,
              G = (num >> 8 & 0x00FF) + amt,
              B = (num & 0x0000FF) + amt;
        return `#${(
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
            (B < 255 ? (B < 1 ? 0 : B) : 255)
        )
            .toString(16)
            .slice(1)}`;
    };

    useEffect(() => {
        console.log("SendButton: Initialized with primaryColor =", primaryColor);

        // Set icon color based on user input and mode
        if (userInput.trim()) {
            setIconColor(primaryColor);
            console.log("SendButton: iconColor set to primaryColor:", primaryColor);
        } else {
            setIconColor(isDarkMode ? '#3c3c3e' : '#f0f0f5');
            console.log("SendButton: iconColor reset due to empty input. Color set to:", iconColor);
        }

        if (onSvgReRender) {
            onSvgReRender();
            console.log("SendButton: onSvgReRender called.");
        }
    }, [userInput, primaryColor, isDarkMode, onSvgReRender]);

    const handleClick = (e) => {
        e.preventDefault();
        console.log("SendButton: handleClick triggered.");
        notifyClick();
        if (userInput.trim()) {
            sendMessage(userInput);
        }
    };

    return (
        <button
            ref={sendButtonRef}
            type="submit"
            disabled={!userInput.trim()}
            className="send-button"
            onClick={handleClick}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: isDarkMode ? '#1E1E1E' : '#ffffff', // Swap background color with SVG color
                marginRight: '0px',
                marginBottom: '3px',
                marginTop: '2.5px',
                border: 'none',
                borderRadius: '50%',  // Ensure circular click area
                width: '40px',
                height: '30px',
                cursor: userInput.trim() ? 'pointer' : 'not-allowed', // Change cursor when enabled
                pointerEvents: userInput.trim() ? 'auto' : 'none', // Ensure clickability only when enabled
            }}
        >
            <SendIcon
                style={{
                    color: iconColor, // Use the adjusted icon color instead
                    width: '100%', // Adjust to fill button area
                    height: '100%', // Adjust to fill button area
                    borderRadius: '50%',
                }}
            />
        </button>
    );
};

export default SendButton;
