import React, { useContext, useRef, useState, useEffect } from 'react';
import { ThemeContext } from './ThemeContext.js';
import './Options.css';
import { ReactComponent as SettingsIcon } from './settings.svg';
import Settings from './Settings';

const ORIGINAL_BLUE = '#007bff';

// Utility functions
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const getLuminance = (hex) => {
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 255;
  const g = (rgb >> 8) & 255;
  const b = rgb & 255;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const lightenHexColor = (hex, amount = 10) => {
  hex = hex.replace('#', '');
  let r = Math.min(255, parseInt(hex.substring(0, 2), 16) + amount);
  let g = Math.min(255, parseInt(hex.substring(2, 4), 16) + amount);
  let b = Math.min(255, parseInt(hex.substring(4, 6), 16) + amount);
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const darkenHexColor = (hex, amount = 10) => {
  hex = hex.replace('#', '');
  let r = Math.max(0, parseInt(hex.substring(0, 2), 16) - amount);
  let g = Math.max(0, parseInt(hex.substring(2, 4), 16) - amount);
  let b = Math.max(0, parseInt(hex.substring(4, 6), 16) - amount);
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const getBackgroundColor = (primaryColor, isDarkMode) => {
  if (primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()) {
    return isDarkMode ? '#1E1E1E' : '#000000';
  }
  return primaryColor;
};

const Options = ({
  toggleOptions,
  isOptionsOpen,
  chatButtonRealTop,
  chatButtonRealBottom,
  chatButtonRealLeft,
  chatButtonRealRight,
  chatButtonRealWidth,
  chatButtonRealHeight,
  chatRealBottom,
  chatRealRight,
  chatRealWidth,
  chatRealHeight,
  updateOptionsDimensions,
  buttonRef,
}) => {
  const {
    isDarkMode,
    primaryColor,
    zIndices,
    isSettingsOpen,
    toggleSettingsOpen,
    isNewButtonClicked,
  } = useContext(ThemeContext);

  const settingsButtonRef = useRef(null);
  const [hovered, setHovered] = useState(false);

  const isMobile = isMobileDevice();

  // New Logic: Define velma and adjust textColor based on isNewButtonClicked
  const velma = isDarkMode ? '#1e1e1e' : '#ffffff';
  const luminance = isNewButtonClicked ? getLuminance(velma) : getLuminance(primaryColor);
  const textColor = isNewButtonClicked
    ? luminance > 128
      ? '#1E1E1E'
      : '#f0f0f5'
    : luminance > 128
    ? '#1E1E1E'
    : '#f0f0f5';

  const handleOptionsButtonClick = () => {
    toggleSettingsOpen();
  };

  // Adjust backgroundColor based on isNewButtonClicked
  const backgroundColor = isNewButtonClicked
    ? velma
    : getBackgroundColor(primaryColor, isDarkMode);

  // Updated hoverBackgroundColor to include new condition
  const hoverBackgroundColor =
    isNewButtonClicked && !isDarkMode
      ? '#f0f0f5' // New hover color for light mode when isNewButtonClicked is true
      : isDarkMode && isNewButtonClicked
      ? '#4B4B4B' // 20% lighter than '#1e1e1e'
      : primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 25)
      : darkenHexColor(backgroundColor, 35);

  const borderColor =
    primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 25)
      : darkenHexColor(backgroundColor, 35);

  return (
    <>
      {isOptionsOpen && (
        <div style={{ position: 'absolute', display: 'inline-block' }}>
          <button
            key={isNewButtonClicked ? 'new' : 'old'}
            onClick={(e) => {
              e.stopPropagation();
              handleOptionsButtonClick();
            }}
            ref={settingsButtonRef}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
              position: 'fixed',
              top: isNewButtonClicked ? '118px' : isMobile ? '92.5px' : '92.5px',
              left: isNewButtonClicked ? '160px' : `calc(90px)`,
              zIndex: zIndices.options + 1,
              backgroundColor: hovered || isSettingsOpen ? hoverBackgroundColor : 'transparent',
              border: 'none',
              cursor: 'pointer',
              padding: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '1vh',
              padding: '5px',
              width: isNewButtonClicked ? '30px' : '3.5vw',
              height: isNewButtonClicked ? '30px' : '3.5vw',
              maxWidth: isNewButtonClicked ? 'none' : '32.5px',
              maxHeight: isNewButtonClicked ? 'none' : '32.5px',
              minHeight: isMobile ? '0' : '32.5px',
              minWidth: isMobile ? '0' : '32.5px',
              transition: 'background-color 0.3s ease',
            }}
          >
            <SettingsIcon
              key={isNewButtonClicked ? 'new-icon' : 'old-icon'}
              style={{
                width: '100%',
                height: '100%',
                fill: textColor, // Ensures SVG uses the updated textColor
              }}
            />
          </button>

          {hovered && !isSettingsOpen && (
            <div
              style={{
                position: 'fixed',
                left: isNewButtonClicked ? '108px' : '125px',
                top: isNewButtonClicked ? '150px' : '125px',
                backgroundColor: backgroundColor,
                color: textColor,
                padding: '2.5px 5px',
                borderRadius: '4px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                whiteSpace: 'nowrap',
                zIndex: zIndices.options + 1,
                border: isNewButtonClicked
                  ? isDarkMode
                    ? `2px solid #3c3c3e`
                    : `2px solid ${borderColor}`
                  : `2px solid ${borderColor}`,
                fontSize: '12px',
              }}
            >
              Display
            </div>
          )}
        </div>
      )}

      {isSettingsOpen && (
        <Settings
          settingsButtonRef={settingsButtonRef}
          distance={{
            bottom: chatButtonRealBottom,
            right: chatButtonRealRight,
            width: chatButtonRealWidth,
            height: chatButtonRealHeight,
          }}
          ChatBottom={chatButtonRealBottom}
          chatRealBottom={chatRealBottom}
          chatRealRight={chatRealRight}
          chatRealWidth={chatRealWidth}
          chatRealHeight={chatRealHeight}
        />
      )}
    </>
  );
};

export default Options;
