// Settings.js
import React, { useContext, useEffect, useState, useRef } from 'react';
import { ThemeContext } from './ThemeContext.js';
import '@radial-color-picker/react-color-picker/dist/style.css';
import ColorPicker from '@radial-color-picker/react-color-picker';
import './Settings.css';
// Import logo and close icons as React components
import { ReactComponent as LogoIcon } from './logo.svg';
import { ReactComponent as CloseIcon } from './close.svg'; // Added import for CloseIcon
import { ReactComponent as SunIcon } from './sun.svg';
import { ReactComponent as MoonIcon } from './moon.svg';
import anime from 'animejs/lib/anime.es.js';
import { adjustTextColor, getBackgroundColor } from './utils.js';

// Helper functions
const hexToHue = (hex) => {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let hue = 0;

  if (max === min) {
    hue = 0;
  } else if (max === r) {
    hue = ((g - b) / (max - min)) * 60;
  } else if (max === g) {
    hue = (2.0 + (b - r) / (max - min)) * 60;
  } else {
    hue = (4.0 + (r - g) / (max - min)) * 60;
  }

  if (hue < 0) hue += 360;
  return hue;
};

const hexToRgba = (hex, opacity = 1) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const hueToHex = (hue) => {
  const s = 1;
  const l = 0.5;
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((hue / 60) % 2 - 1));
  const m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (hue >= 0 && hue < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (hue >= 60 && hue < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (hue >= 120 && hue < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (hue >= 180 && hue < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (hue >= 240 && hue < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (hue >= 300 && hue < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

// Helper Functions to Darken or Lighten Hex Colors
const darkenHexColor = (hex, amount = 10) => {
  hex = hex.replace('#', '');
  let r = Math.max(0, parseInt(hex.substring(0, 2), 16) - amount);
  let g = Math.max(0, parseInt(hex.substring(2, 4), 16) - amount);
  let b = Math.max(0, parseInt(hex.substring(4, 6), 16) - amount);

  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const lightenHexColor = (hex, amount = 10) => {
  hex = hex.replace('#', '');
  let r = Math.min(255, parseInt(hex.substring(0, 2), 16) + amount);
  let g = Math.min(255, parseInt(hex.substring(2, 4), 16) + amount);
  let b = Math.min(255, parseInt(hex.substring(4, 6), 16) + amount);

  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const getLuminance = (hex) => {
  hex = hex.replace('#', '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const Settings = ({
  ChatBottom,
  OptionsRealRight,
  OptionsRealWidth,
  OptionsRealHeight,
  chatRealBottom,
  chatRealRight,
  chatRealWidth,
  chatRealHeight,
  distance,
  settingsButtonRef, // Added to check if the click is on the settings button
}) => {
  const {
    isDarkMode,
    toggleTheme,
    primaryColor,
    updatePrimaryColor,
    resetPrimaryColor,
    zIndices,
    toggleSettingsOpen,
    isNewButtonClicked,
    viewportWidth,
    viewportHeight,
    minViewportDimension,
  } = useContext(ThemeContext);

  const [pickerSize, setPickerSize] = useState(null);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [brightness, setBrightness] = useState(100);
  const [isSunHovered, setIsSunHovered] = useState(false);
  const [isMoonHovered, setIsMoonHovered] = useState(false);
  const containerRef = useRef(null);
  const resetButtonRef = useRef(null);
  const brightnessRef = useRef(null);
  const clickTimerRef = useRef(null);
  const [position, setPosition] = useState({ top: '0px', left: '0px' });

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const hueColor = hexToHue(primaryColor);

  // Added 'velma' variable
  const velma = isDarkMode ? '#1e1e1e' : '#ffffff';

  // Updated backgroundColor calculation
  const backgroundColor = isNewButtonClicked
    ? velma
    : getBackgroundColor(primaryColor, isDarkMode);

  const ORIGINAL_BLUE = '#007bff';

  // Updated borderColor calculation based on new requirement
  const borderColor =
    isDarkMode && isNewButtonClicked
      ? '#3c3c3e'
      : primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 25)
      : darkenHexColor(backgroundColor, 35);

  // Updated iconColor calculation based on isNewButtonClicked
  const iconColor = isNewButtonClicked
    ? isDarkMode
      ? '#f0f0f5'
      : '#1e1e1e'
    : isDarkMode
    ? getLuminance(primaryColor) > 128
      ? '#1E1E1E'
      : '#f0f0f5'
    : getLuminance(primaryColor) > 128
    ? '#1E1E1E'
    : '#f0f0f5';

  useEffect(() => {
    if (settingsButtonRef.current && !isNewButtonClicked) {
      const buttonRect = settingsButtonRef.current.getBoundingClientRect();
      setPosition({
        left: `${buttonRect.left}px`,
      });
    }
  }, [settingsButtonRef, isNewButtonClicked]);

  // Handlers for hover effects
  const handleSunMouseEnter = () => setIsSunHovered(true);
  const handleSunMouseLeave = () => setIsSunHovered(false);
  const handleMoonMouseEnter = () => setIsMoonHovered(true);
  const handleMoonMouseLeave = () => setIsMoonHovered(false);

  // Handlers for brightness control
  const handleBrightnessChange = (yPos) => {
    if (!brightnessRef.current) return;
    const { top, height } = brightnessRef.current.getBoundingClientRect();
    const newY = yPos - top;
    const newBrightness = 100 - Math.min(100, Math.max(0, (newY / height) * 100));
    setBrightness(newBrightness);
    document.documentElement.style.setProperty('--brightness', newBrightness / 100);
  };

  const handleBrightnessDrag = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseBrightnessChange);
    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', handleMouseBrightnessChange);
      },
      { once: true }
    );
  };

  const handleMouseBrightnessChange = (e) => {
    handleBrightnessChange(e.clientY);
  };

  const handleTouchBrightnessChange = (e) => {
    if (e.touches && e.touches.length > 0) {
      handleBrightnessChange(e.touches[0].clientY);
    }
  };

  const handleTouchStart = (e) => {
    handleTouchBrightnessChange(e);
    document.addEventListener('touchmove', handleTouchBrightnessChange);
    document.addEventListener(
      'touchend',
      () => {
        document.removeEventListener('touchmove', handleTouchBrightnessChange);
      },
      { once: true }
    );
  };

  // Calculate picker sizes on mount and resize
  useEffect(() => {
    const calculateSizes = () => {
      if (containerRef.current) {
        const { height, width } = containerRef.current.getBoundingClientRect();
        const buttonSize = Math.max(height, width) * (14 / 16); // Ensure square size
        setPickerSize(buttonSize);
      }
    };

    calculateSizes();
    window.addEventListener('resize', calculateSizes);

    return () => {
      window.removeEventListener('resize', calculateSizes);
    };
  }, []);

  // Apply brightness filter
  useEffect(() => {
    document.documentElement.style.filter = `brightness(${brightness}%)`;
  }, [brightness]);

  // Handle color changes from the color picker
  const handleColorChange = (color) => {
    const hexColor = hueToHex(color);
    updatePrimaryColor(hexColor);
    handleResetColor();
    setTimeout(() => {
      handleResetColor();
    }, 100);
  };

  // Handle reset color button clicks
  const handleResetColor = () => {
    if (clickTimerRef.current) {
      clearTimeout(clickTimerRef.current);
      clickTimerRef.current = null;

      const colorPickerWell = document.querySelector('.rcp__well');
      if (colorPickerWell) {
        colorPickerWell.click();
        setIsEnlarged(!isEnlarged);
        anime({
          targets: resetButtonRef.current,
          scale: isEnlarged ? 1 : 2.0,
          duration: 300,
          easing: 'easeInOutQuad',
          delay: 500,
        });
      }
    } else {
      clickTimerRef.current = setTimeout(() => {
        resetPrimaryColor();
        clickTimerRef.current = null;
      }, 1000);
    }
  };

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        settingsButtonRef.current &&
        !settingsButtonRef.current.contains(event.target)
      ) {
        toggleSettingsOpen(false); // Close settings via ThemeContext
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleSettingsOpen, settingsButtonRef]);

  // Listen to window resize to update windowHeight
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Conditional Overlay */}
      {isNewButtonClicked && (
        <div
          className="settings-overlay-background"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: zIndices.settings - 1, // Ensure it's behind the settings container
          }}
        />
      )}

      {/* Settings Container */}
      <div
        style={{
          position: isNewButtonClicked ? 'fixed' : 'absolute',
          top: isNewButtonClicked ? 'calc(50%)' : '125px',
          left: isNewButtonClicked ? '50%' : `calc(${position.left} + 0vw)`,
          right: isNewButtonClicked ? 'auto' : 'none',
          transform: isNewButtonClicked
            ? 'translate(-50%, -50%) scale(0.5)'
            : 'scale(0.5)',
          transformOrigin: isNewButtonClicked ? 'center' : 'top left',
          zIndex: zIndices.settings,
          display: 'flex',
          justifyContent: isNewButtonClicked ? 'center' : 'auto',
          alignItems: isNewButtonClicked ? 'center' : 'auto',
        }}
      >
        <div
          className={`settings-overlay ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
          style={{
            bottom: isMobile ? '10vh' : '10vh',
            left: isNewButtonClicked ? '0' : `calc(85px + 4vw)`,
            transform: isNewButtonClicked ? 'none' : 'none',
            transition: 'bottom 0.3s ease',
            width: isNewButtonClicked
              ? '100vmin'
              : isMobile
              ? '40vh'
              : '40vh',
            height: isNewButtonClicked
              ? '100vmin'
              : isMobile
              ? '30vh'
              : '30vh',
            borderRadius: isMobile ? '' : '3vh',
            overflow: isMobile ? 'hidden' : 'visible',
            outline: `3.5px solid ${borderColor}`,
            marginTop: '0px',
            marginLeft: '0px',
          }}
        >
          <div
            className="settings-container"
            ref={containerRef}
            style={{
              backdropFilter: 'none',
              background: backgroundColor, // Ensures background matches Down.js
              borderRadius: isMobile ? '0' : '3vh',
              position: isNewButtonClicked ? 'absolute' : 'relative',
              boxShadow: `2px 2px 0px ${borderColor}`,
              outline: `2px solid ${borderColor}`, // Ensures the border matches Down.js
              width: '100%',
              //top: '10px',
            }}
          >
            {/* Conditionally Render Close Button When isNewButtonClicked is True */}
            {isNewButtonClicked && (
              <button
                className="close-button"
                onClick={() => toggleSettingsOpen(false)}
                style={{
                  position: 'absolute',
                  top: 'calc(2.5% + 5vmin)',
                  left: 'calc(2.5% + 5vmin)',
                  width: '15vmin',
                  height: '15vmin',
                  borderRadius: '50%',
                  border: `2px solid ${borderColor}`,
                  backgroundColor: iconColor,
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0',
                  outline: `0.2vh solid ${borderColor}`,
                  zIndex: 5,
                }}
                aria-label="Close Settings"
              >
                <CloseIcon
                  style={{
                    width: '60%',
                    height: '60%',
                    fill: 'currentColor', // Updated fill to currentColor
                    color: borderColor,
                    strokeWidth: '2px',
                  }}
                  alt="Close"
                />
              </button>
            )}

            {/* Color Picker */}
            <div
              className="color-picker-container"
              style={{
                position: isNewButtonClicked ? 'absolute' : 'absolute',
                top: isNewButtonClicked ? 'none' : '5%',
                bottom: isNewButtonClicked ? 'calc(2.5% + 5vmin)' : 'none',
                left: isNewButtonClicked ? 'calc(5vmin)' : '3.5%',
                width: isNewButtonClicked ? `60vmin` : `${pickerSize * 1.4}px`,
                height: isNewButtonClicked ? `60vmin` : `${pickerSize * 1.4}px`,
                minWidth: isNewButtonClicked ? 'none' : 'none',
                minHeight: isNewButtonClicked ? 'none' : 'none',
                boxSizing: 'border-box',
              }}
            >
              {/* Outer Circle Behind the Color Picker */}
              <div
                className="color-picker-circle"
                style={{
                  position: 'absolute',
                  top: isNewButtonClicked ? '-1%' : '-2%',
                  left: isNewButtonClicked ? '-1%' : '-2%',
                  width: isNewButtonClicked ? '102%' : '104%',
                  height: isNewButtonClicked ? '102%' : '104%',
                  borderRadius: '50%',
                  border: `3vh solid ${borderColor}`,
                  outline: `0.15vh solid ${borderColor}`,
                }}
              />
              {/* Inner Circle Behind the Color Picker */}
              <div
                className="color-picker-circle-inner"
                style={{
                  position: 'absolute',
                  top: '12.5%',
                  left: '12.5%',
                  width: '75%',
                  height: '75%',
                  borderRadius: '50%',
                  border: `0.5vh solid ${borderColor}`,
                  outline: `2vh solid ${borderColor}`,
                  zIndex: 0,
                }}
              />
              <ColorPicker color={hueColor} onChange={handleColorChange} />
              <button
                ref={resetButtonRef}
                className="reset-button"
                onClick={handleResetColor}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%',
                  height: '50%',
                  borderRadius: '50%',
                  padding: '0',
                  border: 'none',
                  cursor: 'pointer',
                  zIndex: 2,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  outline: `0.2vh solid ${borderColor}`,
                  backgroundColor: iconColor,
                  color: borderColor,
                }}
              >
                <LogoIcon
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: '15%',
                    filter: 'none',
                  }}
                  alt="Reset"
                />
              </button>
            </div>

            {/* Theme Toggle Button */}
            <div
              className="theme-button-container"
              style={{
                position: 'absolute',
                top: isNewButtonClicked ? '2.5vmin' : '5%',
                right: isNewButtonClicked ? '2.5vmin' : '4.75%',
                width: `${pickerSize / 1.75}px`,
                height: `${pickerSize / 1.75}px`,
                boxSizing: 'border-box',
              }}
            >
              <button
                className="icon-button"
                onClick={toggleTheme}
                onMouseEnter={isDarkMode ? handleSunMouseEnter : handleMoonMouseEnter}
                onMouseLeave={isDarkMode ? handleSunMouseLeave : handleMoonMouseLeave}
                style={{
                  width: isDarkMode ? '90%' : '80%',
                  height: isDarkMode ? '90%' : '80%',
                  padding: isNewButtonClicked ? 'none' : '5px',
                  border: 'none',
                  cursor: 'pointer',
                  zIndex: 3,
                  overflow: 'hidden',
                  color: iconColor,
                }}
              >
                {isDarkMode ? (
                  <SunIcon
                    className={`sun-icon ${isSunHovered ? 'hover' : ''}`}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                ) : (
                  <MoonIcon
                    className={`moon-icon ${isMoonHovered ? 'hover' : ''}`}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </button>
            </div>

            {/* Brightness Control */}
            <div
              className="brightness-control-container"
              ref={brightnessRef}
              style={{
                position: 'absolute',
                top: isNewButtonClicked ? 'none' : '42%',
                bottom: isNewButtonClicked ? 'calc(5% + 5vmin)' : 'none',
                right: isNewButtonClicked ? 'calc(2.5% + 5vmin)' : '10.5%',
                width: isNewButtonClicked ? `${pickerSize / 3}px` : `${pickerSize / 3.5}px`,
                height: isNewButtonClicked ? '57.5vmin' : '47.5%',
                background: isDarkMode
                  ? 'rgba(42, 42, 42, 1)'
                  : 'rgba(40, 40, 40, 0.5)',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 4,
              }}
              onMouseDown={handleBrightnessDrag}
              onTouchStart={handleTouchStart}
            >
              <div
                className="brightness-control"
                style={{
                  width: '100%',
                  height: '100%',
                  background: `
                        linear-gradient(to top, 
                        ${isDarkMode ? iconColor : iconColor} ${brightness}%, 
                        ${
                          isDarkMode
                            ? 'rgba(42, 42, 42, 0.75)'
                            : 'rgba(40, 40, 40, 0.3)'
                        } ${brightness}%),
                        ${
                          isDarkMode
                            ? 'rgba(42, 42, 42, 0.75)'
                            : 'rgba(255, 255, 255, 0.25)'
                        }
                      `,
                  position: 'relative',
                  outline: `0.675vh solid ${borderColor}`,
                }}
              >
                <div
                  className="brightness-thumb"
                  style={{
                    position: 'absolute',
                    left: '0',
                    width: '100%',
                    top: `${100 - brightness}%`,
                    height: '2px',
                    backgroundColor: 'transparent',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
