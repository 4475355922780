// AthenaAnimation.js

import React, { forwardRef, useContext } from 'react';
import Lottie from 'lottie-react';
import athenaAnimationData from './Athena.json'; // Ensure the path is correct
import { ThemeContext } from './ThemeContext.js'; // Ensure ThemeContext is correctly exported

const AthenaAnimation = forwardRef((props, ref) => {
  const { isDarkMode, primaryColor } = useContext(ThemeContext);

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        ...props.style,
        // Removed the dynamic filter to maintain animation consistency
      }}
    >
      <Lottie
        animationData={athenaAnimationData}
        loop
        {...props}
      />
    </div>
  );
});

export default AthenaAnimation;
