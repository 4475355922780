import React from 'react';
import { ThemeProvider } from './ThemeContext.js';  // Import ThemeProvider
import { AppProvider as PolarisProvider, Page } from '@shopify/polaris';
import ChatButton from './ChatButton.js';
import Chat from './Chat.js';
import Settings from './Settings.js';
import Options from './Options.js';
import LogWrapper from './LogWrapper.js';  // New wrapper component for logging

const AppContent = () => {
    return (
        <ThemeProvider>
            <LogWrapper>
                <PolarisProvider>
                    <Page>
                        <Chat />
                        <ChatButton />
                    </Page>
                </PolarisProvider>
            </LogWrapper>
        </ThemeProvider>
    );
};

export default AppContent;


