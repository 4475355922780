// utils.js

/**
 * Calculates the luminance of a HEX color.
 * Used to determine appropriate text color based on background.
 *
 * @param {string} hex - The HEX color (e.g., '#ffffff').
 * @returns {number} - The luminance value.
 */
export const getLuminance = (hex) => {
    const rgb = parseInt(hex.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };
  
  /**
   * Determines the appropriate text color based on the primary color's luminance.
   * Returns dark gray for light backgrounds and white for dark backgrounds.
   *
   * @param {string} primaryColor - The primary color in HEX format.
   * @returns {string} - The HEX color for the text.
   */
  export const adjustTextColor = (primaryColor) => {
    const luminance = getLuminance(primaryColor);
    return luminance > 128 ? '#1e1e1e' : '#ffffff';
  };
  
  /**
   * Detects if the current device is a mobile device.
   *
   * @returns {boolean} - True if mobile device, else false.
   */
  export const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);
  
  /**
   * Encodes the primary color for use in data URIs.
   * Replaces the '#' character with '%23' to ensure proper URL encoding.
   *
   * @param {string} primaryColor - The primary color in HEX format.
   * @returns {string} - The URL-encoded color string.
   */
  export const getWaveColor = (primaryColor) => primaryColor.replace('#', '%23');
  
  /**
   * Determines the background color based on the primary color and theme mode.
   * Adjusts specific colors as needed for better visual consistency.
   *
   * @param {string} primaryColor - The primary color in HEX format.
   * @param {boolean} isDarkMode - Flag indicating if dark mode is active.
   * @returns {string} - The computed background color.
   */
  export const getBackgroundColor = (primaryColor, isDarkMode) => {
    if (primaryColor === '#007bff') {
      return isDarkMode ? '#1E1E1E' : '#000000';
    }
    return primaryColor;
  };
  