import React, { useContext, useEffect } from 'react';
import { ThemeContext } from './ThemeContext.js';  // Import ThemeContext

const LogWrapper = ({ children }) => {
    // Consume ThemeContext directly in LogWrapper
    const { isOptionsOpen, isDarkMode, primaryColor } = useContext(ThemeContext);

    // Log the context state to verify it is being received
    useEffect(() => {
        console.log('LogWrapper received isOptionsOpen:', isOptionsOpen);
        console.log('LogWrapper received isDarkMode:', isDarkMode);
        console.log('LogWrapper received primaryColor:', primaryColor);
    }, [isOptionsOpen, isDarkMode, primaryColor]);

    return <>{children}</>;
};

export default LogWrapper;
