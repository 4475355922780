import React, { useState, useEffect, useRef, useContext } from 'react';
import './Chat.css';
import Options from './Options.js';
import SendButton from './SendButton.js';
import ConsentUI from './ConsentUI.js';
import { ThemeContext } from './ThemeContext.js';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import { ReactComponent as DownIcon } from './down.svg';
import { ReactComponent as ThumbUpIcon } from './ThumbUp.svg';
import { ReactComponent as ThumbDownIcon } from './ThumbDown.svg';
import { ReactComponent as RefreshIcon } from './refresh.svg';
import anime from 'animejs/lib/anime.es.js';
import { ReactComponent as EditIcon } from './edit.svg';
import Down from './Down';
import { PromptsList } from './prompts.js';
import { adjustTextColor, getBackgroundColor, isMobileDevice, getWaveColor } from './utils.js';
import AthenaAnimation from './AthenaAnimation.js';
import { fetchBotResponse } from './BotService.js';
import LottieAnimation from './LottieAnimation.js';

const Chat = ({
  botMessageColor,
  isChatOpen,
  chatButtonRealTop = 0,
  chatButtonRealBottom = 0,
  chatButtonRealLeft = 0,
  chatButtonRealRight = 0,
  chatButtonRealWidth = 0,
  chatButtonRealHeight = 0,
}) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [chatWidth, setChatWidth] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [latestUserMessageIndex, setLatestUserMessageIndex] = useState(null);
  const [latestBotMessageIndex, setLatestBotMessageIndex] = useState(null);
  const [originalMessage, setOriginalMessage] = useState('');
  const messageRefs = useRef([]);
  const chatWindowRef = useRef(null);
  const chatRef = useRef(null);
  const chatButtonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (chatWindowRef.current) {
        if (window.innerWidth <= 950) {
          chatWindowRef.current.classList.add('small-screen');
          chatWindowRef.current.classList.remove('large-screen');
        } else {
          chatWindowRef.current.classList.add('large-screen');
          chatWindowRef.current.classList.remove('small-screen');
        }
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // **Define separate refs for sending and editing messages**
  const sendTextareaRef = useRef(null);
  const editTextareaRef = useRef(null);

  const [isDownOpen, setIsDownOpen] = useState(false);
  const [isMultiline, setIsMultiline] = useState(false);

  const checkIfMultiline = (text) => {
    const isMultilineMessage = text.includes('\n');
    setIsMultiline(isMultilineMessage);
  };

  const lightenHexColor = (hex, amount = 10) => {
    hex = hex.replace('#', '');
    let r = Math.min(255, parseInt(hex.substring(0, 2), 16) + amount);
    let g = Math.min(255, parseInt(hex.substring(2, 4), 16) + amount);
    let b = Math.min(255, parseInt(hex.substring(4, 6), 16) + amount);
    return `#${r.toString(16).padStart(2, '0')}${g
      .toString(16)
      .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  const darkenHexColor = (hex, amount = 10) => {
    hex = hex.replace('#', '');
    let r = Math.max(0, parseInt(hex.substring(0, 2), 16) - amount);
    let g = Math.max(0, parseInt(hex.substring(2, 4), 16) - amount);
    let b = Math.max(0, parseInt(hex.substring(4, 6), 16) - amount);
    return `#${r.toString(16).padStart(2, '0')}${g
      .toString(16)
      .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  const {
    isDarkMode,
    primaryColor,
    zIndices,
    isOptionsOpen,
    toggleOptionsOpen,
    setIsChatOpen,
    isNewButtonClicked,
    toggleNewButton,
    notifyClick,
    inputWidthLeft,
    inputWidth,
    inputWidthRight,
  } = useContext(ThemeContext);

  const textColor = adjustTextColor(primaryColor);
  const backgroundColor = getBackgroundColor(primaryColor, isDarkMode);
  const waveColor = getWaveColor(backgroundColor);

  const getLuminance = (hex) => {
    const rgb = parseInt(hex.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const luminance = getLuminance(primaryColor);
  const logoColor = isDarkMode
    ? luminance > 128
      ? '#1E1E1E'
      : '#f0f0f5'
    : luminance > 128
    ? '#1E1E1E'
    : '#f0f0f5';

  const [chatPosition, setChatPosition] = useState({ bottom: 100, right: 20 });
  const [optionsDimensions, setOptionsDimensions] = useState({});

  const [storedChatButtonTop, setStoredChatButtonTop] = useState(chatButtonRealTop);
  const [storedChatButtonBottom, setStoredChatButtonBottom] = useState(chatButtonRealBottom);
  const [storedChatButtonLeft, setStoredChatButtonLeft] = useState(chatButtonRealLeft);
  const [storedChatButtonRight, setStoredChatButtonRight] = useState(chatButtonRealRight);
  const [storedChatButtonWidth, setStoredChatButtonWidth] = useState(chatButtonRealWidth);
  const [storedChatButtonHeight, setStoredChatButtonHeight] = useState(chatButtonRealHeight);

  const [chatRealBottom, setChatRealBottom] = useState(0);
  const [chatRealRight, setChatRealRight] = useState(0);
  const [chatRealWidth, setChatRealWidth] = useState(0);
  const [chatRealHeight, setChatRealHeight] = useState(0);

  const isMobile = isMobileDevice();
  const fontSize = 12;

  const wordRevealIntervals = useRef({});
  const athenaRef = useRef(null);

  const [showConsent, setShowConsent] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);

  const [userMessageCount, setUserMessageCount] = useState(0);

  // **Ref to track previous messages length**
  const prevMessagesLength = useRef(messages.length);

  // **Modified useEffect to always scroll to the latest message whenever messages change**
  useEffect(() => {
    const lastMessageRef = messageRefs.current[messages.length - 1];
    if (lastMessageRef) {
      lastMessageRef.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    const updateChatPosition = () => {
      if (chatRef.current && chatButtonRef.current) {
        const chatRect = chatRef.current.getBoundingClientRect();

        setChatWidth(chatRect.width);

        const newChatPosition = {
          bottom: window.innerHeight - chatRect.bottom,
          right: window.innerWidth - chatRect.right,
        };
        setChatPosition(newChatPosition);
      }
    };

    updateChatPosition();
  }, [isChatOpen, isOptionsOpen]);

  useEffect(() => {
    const handleResizeShopify = () => {
      const message = isOptionsOpen
        ? { type: 'chat-resize', width: 'calc(45vw + 5vh)' }
        : { type: 'chat-resize', width: '0' };

      window.parent.postMessage(message, '*');
    };

    handleResizeShopify();
  }, [isOptionsOpen]);

  useEffect(() => {
    const handleShopifyResize = (event) => {
      if (event.data.type === 'chat-resize') {
        const mainContent = document.querySelector('.main-content');
        if (mainContent) {
          mainContent.style.marginRight = event.data.width;
        }
      }
    };

    window.addEventListener('message', handleShopifyResize);

    return () => {
      window.removeEventListener('message', handleShopifyResize);
    };
  }, []);

  useEffect(() => {
    setStoredChatButtonTop(chatButtonRealTop);
    setStoredChatButtonBottom(chatButtonRealBottom);
    setStoredChatButtonLeft(chatButtonRealLeft);
    setStoredChatButtonRight(chatButtonRealRight);
    setStoredChatButtonWidth(chatButtonRealWidth);
    setStoredChatButtonHeight(chatButtonRealHeight);
  }, [
    chatButtonRealTop,
    chatButtonRealBottom,
    chatButtonRealLeft,
    chatButtonRealRight,
    chatButtonRealWidth,
    chatButtonRealHeight,
  ]);

  const handleUpdateOptionsDimensions = (dimensions) => {
    setOptionsDimensions(dimensions);
  };

  useEffect(() => {
    if (chatRef.current) {
      const chatRect = chatRef.current.getBoundingClientRect();
      const calculatedChatRealBottom = isOptionsOpen
        ? chatButtonRealTop + chatButtonRealBottom
        : chatButtonRealTop + chatButtonRealBottom;

      setChatRealBottom(calculatedChatRealBottom);
      setChatRealRight(storedChatButtonRight);
      setChatRealWidth(chatRect.width);
      setChatRealHeight(chatRect.height);
    }
  }, [
    isOptionsOpen,
    optionsDimensions,
    chatButtonRealTop,
    chatButtonRealBottom,
    storedChatButtonRight,
  ]);

  useEffect(() => {
    messages.forEach((msg, index) => {
      if (msg.sender === 'user' && messageRefs.current[index]) {
        const messageDiv = messageRefs.current[index];
        anime({
          targets: messageDiv,
          backgroundColor: primaryColor,
          duration: 500,
          easing: 'easeOutQuad',
        });
      }
    });
  }, [primaryColor, messages]);

  const revealWords = (messageIndex, fullText) => {
    if (fullText === 'loading') return;

    const words = fullText.split(/(\s+)/); // Split on spaces, including newlines
    const totalWords = words.length;
    const maxTotalTime = 5000;
    let intervalTime = 250;
    const totalTimeWithDefault = totalWords * intervalTime;

    if (totalTimeWithDefault > maxTotalTime) {
      intervalTime = maxTotalTime / totalWords;
    }

    let currentIndex = 0;

    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      newMessages[messageIndex].isRevealing = true;
      if (newMessages[messageIndex].text === 'loading') {
        newMessages[messageIndex].text = '';
      }
      newMessages[messageIndex].text = words.slice(0, currentIndex + 1).join('');
      return newMessages;
    });

    if (totalWords === 1) {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[messageIndex].isRevealing = false;
        return newMessages;
      });
      return;
    }

    wordRevealIntervals.current[messageIndex] = setInterval(() => {
      currentIndex++;

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[messageIndex].text = words.slice(0, currentIndex + 1).join('');
        return newMessages;
      });

      if (currentIndex >= totalWords - 1) {
        clearInterval(wordRevealIntervals.current[messageIndex]);
        delete wordRevealIntervals.current[messageIndex];

        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[messageIndex].isRevealing = false;
          return newMessages;
        });
      }
    }, intervalTime);
  };

  // **Updated sendMessage Function**
  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;

    notifyClick();

    const userMessage = { sender: 'user', text: messageText };
    const loadingMessage = { sender: 'bot', text: 'loading', isRevealing: false };

    const newMessages = [...messages, userMessage, loadingMessage];
    setMessages(newMessages);

    const newLatestUserMessageIndex = newMessages.length - 2;
    const newLatestBotMessageIndex = newMessages.length - 1;

    setLatestUserMessageIndex(newLatestUserMessageIndex);
    setLatestBotMessageIndex(newLatestBotMessageIndex);

    setUserInput('');

    setUserMessageCount((prevCount) => prevCount + 1);

    checkIfMultiline(userMessage.text);

    // **Ensure send textarea resets properly**
    if (sendTextareaRef.current) {
      sendTextareaRef.current.style.height = 'auto';
      sendTextareaRef.current.style.width = '100%';
    }

    const hasConsent = sessionStorage.getItem('hasConsent') === 'true';
    if (userMessageCount === 0 && !hasConsent) {
      setShowConsent(true);
      setPendingMessage(userMessage);
      return;
    }

    try {
      const botResponseText = await fetchBotResponse(userMessage);

      revealWords(newLatestBotMessageIndex, botResponseText);
    } catch (error) {
      if (error.message === 'ConsentRequired') {
        setShowConsent(true);
        setPendingMessage(userMessage);
      } else {
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newLatestBotMessageIndex] = {
            sender: 'bot',
            text: 'Sorry, something went wrong. Please try again.',
            isRevealing: false,
          };
          return newMessages;
        });
      }
    }
  };

  // **Updated handleSubmit Function**
  const handleSubmit = async (e) => {
    e.preventDefault();
    // **Retrieve the latest message value directly from the sendTextareaRef**
    const message = sendTextareaRef.current ? sendTextareaRef.current.value : '';
    await sendMessage(message);
  };

  const handleRefresh = async () => {
    if (latestUserMessageIndex === null || latestBotMessageIndex === null) return;

    const lastUserMessage = messages[latestUserMessageIndex].text;

    const newMessages = messages.slice(0, latestUserMessageIndex + 1);

    const loadingMessage = { sender: 'bot', text: 'loading', isRevealing: false };
    newMessages.push(loadingMessage);
    setMessages(newMessages);

    const newLatestBotMessageIndex = newMessages.length - 1;
    setLatestBotMessageIndex(newLatestBotMessageIndex);

    const hasConsent = sessionStorage.getItem('hasConsent') === 'true';
    if (userMessageCount === 0 && !hasConsent) {
      setShowConsent(true);
      setPendingMessage({ sender: 'user', text: lastUserMessage });
      return;
    }

    try {
      const botResponseText = await fetchBotResponse({ userMessage: lastUserMessage });

      revealWords(newLatestBotMessageIndex, botResponseText);
    } catch (error) {
      if (error.message === 'ConsentRequired') {
        setShowConsent(true);
        setPendingMessage({ sender: 'user', text: lastUserMessage });
      } else {
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newLatestBotMessageIndex] = {
            sender: 'bot',
            text: 'Sorry, something went wrong. Please try again.',
            isRevealing: false,
          };
          return newMessages;
        });
      }
    }
  };

  const toggleOptions = () => {
    toggleOptionsOpen();

    setTimeout(() => {
      if (chatRef.current && chatButtonRef.current) {
        const chatRect = chatRef.current.getBoundingClientRect();

        const newChatPosition = {
          bottom: window.innerHeight - chatRect.bottom,
          right: window.innerWidth - chatRect.right,
        };
        setChatPosition(newChatPosition);
      }
    }, 300);
  };

  const toggleDown = () => {
    setIsDownOpen((prevIsDownOpen) => !prevIsDownOpen);
  };

  useEffect(() => {
    setIsChatOpen(isChatOpen);
  }, [isChatOpen, setIsChatOpen]);

  useEffect(() => {
    if (isOptionsOpen) {
      setIsDownOpen(false);
    }
  }, [isOptionsOpen]);

  const handleEditClick = (index) => {
    setEditMode(true);
    setLatestUserMessageIndex(index);
    setOriginalMessage(messages[index].text);

    setTimeout(() => {
      if (editTextareaRef.current && chatRef.current) {
        editTextareaRef.current.style.height = 'auto';
        editTextareaRef.current.style.height = `${editTextareaRef.current.scrollHeight}px`;

        const chatContainerWidth = chatRef.current.offsetWidth;
        editTextareaRef.current.style.width = `${(chatContainerWidth * 3) / 4}px`;
      }
    }, 0);
  };

  const handleSaveEdit = async () => {
    setEditMode(false);

    if (latestUserMessageIndex === null) return;

    const editedMessage = messages[latestUserMessageIndex].text;

    const newMessages = messages.slice(0, latestUserMessageIndex + 1);

    const loadingMessage = { sender: 'bot', text: 'loading', isRevealing: false };
    newMessages.push(loadingMessage);
    setMessages(newMessages);

    const newLatestBotMessageIndex = newMessages.length - 1;
    setLatestBotMessageIndex(newLatestBotMessageIndex);

    const hasConsent = sessionStorage.getItem('hasConsent') === 'true';
    if (userMessageCount === 0 && !hasConsent) {
      setShowConsent(true);
      setPendingMessage({ sender: 'user', text: editedMessage });
      return;
    }

    try {
      const botResponseText = await fetchBotResponse({ userMessage: editedMessage });

      revealWords(newLatestBotMessageIndex, botResponseText);
    } catch (error) {
      if (error.message === 'ConsentRequired') {
        setShowConsent(true);
        setPendingMessage({ sender: 'user', text: editedMessage });
      } else {
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newLatestBotMessageIndex] = {
            sender: 'bot',
            text: 'Sorry, something went wrong. Please try again.',
            isRevealing: false,
          };
          return newMessages;
        });
      }
    }
  };

  const handleCancelEdit = () => {
    if (latestUserMessageIndex === null) return;

    setMessages((prevMessages) =>
      prevMessages.map((m, i) =>
        i === latestUserMessageIndex ? { ...m, text: originalMessage } : m
      )
    );
    setEditMode(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (editMode) {
        handleSaveEdit();
      } else {
        handleSubmit(e);
      }
    }
  };

  const handleConsentAgree = async () => {
    setShowConsent(false);
    sessionStorage.setItem('hasConsent', 'true');

    if (pendingMessage) {
      try {
        const botResponseText = await fetchBotResponse(pendingMessage);
        revealWords(latestBotMessageIndex, botResponseText);
        setPendingMessage(null);
      } catch (error) {
        if (error.message === 'ConsentRequired') {
          setShowConsent(true);
        } else {
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[latestBotMessageIndex] = {
              sender: 'bot',
              text: 'Sorry, something went wrong. Please try again.',
              isRevealing: false,
            };
            return newMessages;
          });
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      Object.values(wordRevealIntervals.current).forEach(clearInterval);
    };
  }, []);

  const headerHeight = 192.5;

  const [isDownHovered, setIsDownHovered] = useState(false);
  const [isOptionsHovered, setIsOptionsHovered] = useState(false);
  const [isNewButtonHovered, setIsNewButtonHovered] = useState(false);

  const ORIGINAL_BLUE = '#007bff';
  const getTooltipBackgroundColor =
    primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 25)
      : darkenHexColor(backgroundColor, 35);

  useEffect(() => {
    const newButtonElement = document.querySelector('.new-button');
    if (newButtonElement) {
      newButtonElement.style.transform = 'scale(1)';
      setTimeout(() => {
        newButtonElement.style.transform = '';
      }, 0);
    }
  }, [isNewButtonClicked]);

  // **Added useEffect to reset send textarea height when userInput is cleared**
  useEffect(() => {
    if (userInput === '') {
      if (sendTextareaRef.current) {
        sendTextareaRef.current.style.height = 'auto';
        // Optionally, set to a default height
        // sendTextareaRef.current.style.height = '40px';
      }
    }
  }, [userInput]);

  // **Added useEffect to dynamically update scrollbar styles based on theme**
  useEffect(() => {
    // Create a style element
    const style = document.createElement('style');
    style.type = 'text/css';

    // Define scrollbar styles based on theme
    const scrollbarStyles = `
      .chat-window::-webkit-scrollbar {
          width: 15px;
      }

      /* For Firefox */
      .chat-window {
          scrollbar-width: auto;
      }

      /* Scrollbar styles for the edit-textarea */
      .edit-textarea::-webkit-scrollbar {
          width: 12px;
      }

      .edit-textarea::-webkit-scrollbar-track {
          background: transparent;
      }

      .edit-textarea::-webkit-scrollbar-thumb {
          background-color: var(--scrollbar-thumb-color, #888);
          border-radius: 6px;
          border: 3px solid transparent;
          background-clip: padding-box;
      }

      /* For Firefox */
      .edit-textarea {
          scrollbar-width: thin;
          scrollbar-color: var(--scrollbar-thumb-color, #888) transparent;
      }
    `;

    // Append the styles to the style element
    style.appendChild(document.createTextNode(scrollbarStyles));

    // Append the style element to the head
    document.head.appendChild(style);

    // Cleanup function to remove the style when component unmounts or theme changes
    return () => {
      document.head.removeChild(style);
    };
  }, [isDarkMode]);

  return (
    <>
      {isChatOpen && (
        <div
          className={`chat-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
          ref={chatRef}
          style={{
            position: isMobile ? 'absolute' : 'fixed',
            top: isMobile ? '0' : '0',
            left: isMobile ? '0' : '0',
            zIndex: isNewButtonClicked ? zIndices.chat - 1 : zIndices.chat,
            transition: 'bottom 0.3s ease',
            width: '100%',
            // **Updated height and minHeight to prevent overflow**
            height: isMobile ? (isOptionsOpen ? '600px' : '700px') : '100vh',
            minHeight: isNewButtonClicked ? 'auto' : '0',
            boxShadow: 'none',
            borderRadius: isMobile
              ? isOptionsOpen
                ? '50px'
                : '250px'
              : isOptionsOpen
              ? '0px 0px 0px 0px'
              : '0px 0px 0px 0px',
            overflow: 'hidden', // Ensure content doesn't overflow
            display: 'flex',
            flexDirection: 'column',
            //padding: '10px 15px',
          }}
        >
          {/* Chat Header */}
          <div
            className="chat-header"
            style={{
              height: isNewButtonClicked ? '79px' : `${headerHeight}px`,
              background: isNewButtonClicked
                ? `linear-gradient(to bottom, ${backgroundColor} 69%, transparent 0%)`
                : `linear-gradient(to bottom, ${backgroundColor} 69%, transparent 31%)`,
              width: 'calc(100% + 30px) !important',
              left: isNewButtonClicked ? '0px' : '-15px',
              color: textColor,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 35px',
              zIndex: isNewButtonClicked ? 'auto' : zIndices.chat + 2,
              fontSize: '24px',
              position: 'fixed',
            }}
          >
            {/* Left side - Down icon with hover effects */}
            {!isNewButtonClicked && (
              <div
                className="down-icon"
                onClick={toggleDown}
                onMouseEnter={() => setIsDownHovered(true)}
                onMouseLeave={() => setIsDownHovered(false)}
                style={{
                  cursor: 'pointer',
                  zIndex: zIndices.chat + 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: logoColor,
                  marginLeft: '10px',
                  position: 'relative',
                  borderRadius: '10px',
                  transition: 'background-color 0.3s ease',
                  backgroundColor:
                    isDownHovered || isDownOpen ? getTooltipBackgroundColor : 'transparent',
                }}
              >
                <DownIcon
                  style={{
                    fill: 'currentColor',
                    width: '35px',
                    height: '35px',
                    marginTop: '0px',
                    maxHeight: '35px',
                    maxWidth: '35px',
                    minHeight: isMobile ? '0' : '35px',
                    minWidth: isMobile ? '0' : '35px',
                    padding: '2.5px',
                  }}
                />
                {/* Tooltip for Down Button */}
                {isDownHovered && !isDownOpen && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50px',
                      left: '40px',
                      backgroundColor: backgroundColor,
                      color: textColor,
                      padding: '5px 10px',
                      borderRadius: '4px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                      whiteSpace: 'nowrap',
                      zIndex: zIndices.chat + 3,
                      border: `2px solid ${getTooltipBackgroundColor}`,
                      marginBottom: '5px',
                      transition: 'background-color 0.3s ease',
                      fontWeight: '400',
                      fontSize: '12px',
                      outline: 'none',
                    }}
                  >
                    {isDownOpen ? 'none' : 'Menu'}
                  </div>
                )}
              </div>
            )}

            {/* Right side - Options and NewButton icons with hover effects */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                zIndex: zIndices.chat + 1,
                position: 'relative',
              }}
            >
              {/* Options Icon */}
              <div
                className="options-icon"
                onClick={toggleOptions}
                onMouseEnter={() => setIsOptionsHovered(true)}
                onMouseLeave={() => setIsOptionsHovered(false)}
                style={{
                  cursor: 'pointer',
                  zIndex: zIndices.chat + 1,
                  display: isNewButtonClicked ? 'none' : 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: logoColor,
                  width: '20px',
                  height: '20px',
                  marginTop: '0px',
                  maxHeight: '20px',
                  maxWidth: '20px',
                  minHeight: isMobile ? '0' : '20px',
                  minWidth: isMobile ? '0' : '20px',
                  ...(isOptionsOpen && { marginRight: '25px' }),
                  position: 'relative',
                }}
              >
                {isOptionsOpen ? (
                  <MinusIcon
                    style={{
                      fill: 'currentColor',
                    }}
                  />
                ) : (
                  <PlusIcon
                    style={{
                      fill: 'currentColor',
                    }}
                  />
                )}
                {/* Tooltip for Options Button */}
                {isOptionsHovered && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '40px',
                      right: '0px',
                      backgroundColor: backgroundColor,
                      color: textColor,
                      padding: '5px 10px',
                      borderRadius: '4px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                      whiteSpace: 'nowrap',
                      zIndex: zIndices.chat + 3,
                      border: `2px solid ${getTooltipBackgroundColor}`,
                      marginBottom: '5px',
                      transition: 'background-color 0.3s ease',
                      fontWeight: '400',
                      fontSize: '12px',
                    }}
                  >
                    {isOptionsOpen ? '33%' : '50%'}
                  </div>
                )}
              </div>

              {/* NewButton Icon */}
              {isOptionsOpen && (
                <div
                  className="new-button"
                  onClick={toggleNewButton}
                  onMouseEnter={() => setIsNewButtonHovered(true)}
                  onMouseLeave={() => setIsNewButtonHovered(false)}
                  style={{
                    bottom: isNewButtonClicked ? '62.5px' : 'auto',
                    cursor: 'pointer',
                    display: isNewButtonClicked ? 'flex' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: logoColor,
                    marginRight: '25px',
                    width: isNewButtonClicked ? '30px' : '20px',
                    height: isNewButtonClicked ? '30px' : '20px',
                    marginTop: isNewButtonClicked ? '0px' : '0px',
                    left: isNewButtonClicked ? '25px' : 'none',
                    right: isNewButtonClicked ? 'none' : '0px',
                    maxHeight: isNewButtonClicked ? '30px' : '20px',
                    maxWidth: isNewButtonClicked ? '30px' : '20px',
                    minHeight: isMobile ? '0' : '20px',
                    minWidth: isMobile ? '0' : '20px',
                    position: isNewButtonClicked ? 'fixed' : 'relative',
                    zIndex: isNewButtonClicked ? zIndices.chat + 4 : 'auto',
                  }}
                >
                  {/* Always display PlusIcon */}
                  <PlusIcon
                    style={{
                      fill: 'currentColor',
                    }}
                  />
                  {/* Tooltip for NewButton */}
                  {isNewButtonHovered && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '40px',
                        right: '5px',
                        backgroundColor: backgroundColor,
                        color: textColor,
                        padding: '5px 10px',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                        whiteSpace: 'nowrap',
                        zIndex: zIndices.chat + 3,
                        border: `2px solid ${getTooltipBackgroundColor}`,
                        marginBottom: '5px',
                        transition: 'background-color 0.3s ease',
                        fontWeight: '400',
                        fontSize: '12px',
                      }}
                    >
                      {isNewButtonClicked ? 'Settings' : '100%'}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>  

          {/* Removed Close Button in Top Left when isNewButtonClicked is true */}

          {/* Conditionally Render Down Component Inside chat-container when isNewButtonClicked is false */}
          {!isNewButtonClicked && (
            <Down isOpen={isDownOpen} onClose={() => setIsDownOpen(false)} />
          )}

          {/* Wave Background */}
          {!(isOptionsOpen === false && isMobile) && (
            <div
              className="wave-container"
              style={{
                position: isNewButtonClicked ? 'sticky' : 'absolute',
                top: isNewButtonClicked ? '86.5px' : `${headerHeight - 63.5}px`,
                zIndex: isNewButtonClicked ? zIndices.chat + 5 : zIndices.chat + 1,
                overflow: 'visible',
              }}
            >
              <div
                className="wave"
                style={{
                  background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='${waveColor}'/%3E%3C/svg%3E")`,
                  backgroundRepeat: 'repeat-x',
                  height: '48.7px',
                }}
              ></div>
              <div
                className="wave"
                style={{
                  background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='${waveColor}'/%3E%3C/svg%3E")`,
                  opacity: 0.5,
                  animationDuration: '15s',
                  backgroundRepeat: 'repeat-x',
                  height: '48.7px',
                }}
              ></div>
              <div
                className="wave"
                style={{
                  background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='${waveColor}'/%3E%3C/svg%3E")`,
                  opacity: 0.25,
                  animationDuration: '20s',
                  backgroundRepeat: 'repeat-x',
                  height: '48.7px',
                }}
              ></div>
            </div>
          )}

          {/* Main Content Container */}
          <div
            className="main-content-container"
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflowY: 'hidden',
            }}
          >
            {/* Prompts List */}
            <PromptsList sendMessage={sendMessage} userMessageCount={userMessageCount} />

            {/* Conditionally Render Chat Window Only If There Are Messages */}
            {messages.length > 0 && (
              <div
                className="chat-window-flex-container"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexGrow: 1,
                  overflowY: 'auto',
                  paddingLeft: isNewButtonClicked ? '223px' : '25px',
                  paddingRight: isNewButtonClicked ? '0px' : '25px',
                  marginBottom: '0 !important',
                  boxSizing: 'border-box',
                  //marginTop: '15px',
                }}
              >
                <div
                  className="chat-window conversation-scrollbar"
                  ref={chatWindowRef}
                  style={{
                    width: '100%',
                    maxWidth: '750px',
                    flexGrow: 1,
                    overflowY: 'auto',
                    marginTop: isNewButtonClicked ? '125px' : '190px',
                    paddingBottom: '25px',
                    paddingLeft: isNewButtonClicked ? '50px' : '25px',
                    //paddingRight: paddingRight,
                    boxSizing: 'border-box',
                  }}
                >
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      ref={(el) => (messageRefs.current[index] = el)}
                      className={`chat-message ${msg.sender} ${
                        editMode && index === latestUserMessageIndex ? 'editing' : ''
                      }`} // **Added 'editing' class conditionally**
                      style={{
                        marginLeft: isNewButtonClicked
                          ? 'auto'
                          : isOptionsOpen
                          ? msg.sender === 'user'
                            ? 'auto'
                            : 'calc(1.5em)'
                          : msg.sender === 'user'
                          ? 'auto'
                          : 'calc(1.5em)',
                        marginRight: isNewButtonClicked
                          ? '0'
                          : isOptionsOpen
                          ? msg.sender === 'user'
                            ? '0'
                            : 'auto'
                          : msg.sender === 'user'
                          ? '0'
                          : 'auto',
                        marginTop: index === 0 ? '10px' : '20px',
                        marginBottom:
                          msg.sender === 'user'
                            ? '20px'
                            : index === latestUserMessageIndex && isMultiline
                            ? '40px'
                            : '40px',
                        backgroundColor:
                          msg.sender === 'user'
                            ? primaryColor
                            : msg.text === 'loading'
                            ? 'transparent'
                            : botMessageColor,
                        color:
                          msg.sender === 'user' ? textColor : isDarkMode ? '#f0f0f5' : '#1E1E1E',
                        fontSize: '0.875rem',
                        fontFamily: msg.sender === 'bot' ? "'Helvetica Neue', sans-serif" : 'inherit',
                        minHeight: editMode && index === latestUserMessageIndex ? '80px' : 'auto',
                        position: 'relative',
                        boxShadow:
                          msg.sender === 'user'
                            ? 'auto'
                            : msg.text === 'loading'
                            ? 'none'
                            : isDarkMode
                            ? 'none'
                            : 'none',
                        whiteSpace: 'pre-wrap', // Added to preserve line breaks
                        // **Added maxWidth property based on sender and edit mode**
                        maxWidth: msg.sender === 'bot'
                          ? '100%'
                          : (editMode && index === latestUserMessageIndex)
                          ? '100%'
                          : '75%',
                        width: 'auto', // Ensure width is auto to allow up to maxWidth
                      }}
                    >
                      {/* Bot profile picture */}
                      {msg.sender === 'bot' && (
                        <div
                          className={`bot-avatar ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                          style={{
                            zIndex: zIndices.chat,
                            position: 'absolute',
                            top: '50px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            width: '28px',
                            height: '28px',
                            borderRadius: '50%',
                            overflow: 'visible',
                          }}
                        >
                          <AthenaAnimation
                            isLoading={msg.text === 'loading'}
                            ref={athenaRef}
                            className="bot-avatar-icon"
                            style={{
                              padding: '0em',
                              marginRight: '-25px',
                              width: '56px',
                              height: '56px',
                              flexShrink: 0,
                              borderRadius: '50%',
                              top: '-28px',
                              position: 'relative',
                            }}
                          />
                        </div>
                      )}

                      {/* Conditionally Render Lottie Animation Over Bot Messages Being Revealed */}
                      {msg.isRevealing && msg.sender === 'bot' && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '125%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 10,
                            pointerEvents: 'none',
                          }}
                        >
                          <LottieAnimation />
                        </div>
                      )}

                      {/* Edit Icon for User Messages */}
                      {msg.sender === 'user' && !editMode && (
                        <EditIcon
                          className={`edit-icon ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                          onClick={() => handleEditClick(index)}
                          style={{
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            left: '-27.5px',
                            top: '1.25rem',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                          }}
                        />
                      )}

                      {/* Message Content */}
                      {msg.sender === 'user' && index === latestUserMessageIndex && editMode ? (
                        <>
                          <textarea
                            ref={editTextareaRef} // **Assigned editTextareaRef**
                            className="edit-textarea"
                            name="edit-message" // **Unique name for edit textarea**
                            value={msg.text}
                            onChange={(e) => {
                              setMessages((prevMessages) =>
                                prevMessages.map((m, i) =>
                                  i === index ? { ...m, text: e.target.value } : m
                                )
                              );
                              // Adjust height with limit
                              e.target.style.height = 'auto';
                              const newHeight = Math.min(e.target.scrollHeight, 150);
                              e.target.style.height = `${newHeight}px`;
                            }}
                            onKeyDown={handleKeyDown}
                            rows="1"
                            style={{
                              position: 'relative',
                              maxWidth: '100%',
                              width: 'inherit !important',
                              boxSizing: 'border-box', // Added this line
                              border: 'none',
                              outline: 'none',
                              backgroundColor: 'transparent',
                              color: textColor,
                              marginBottom: '5px',
                              resize: 'none',
                              overflow: 'hidden',
                              overflowY: 'auto',
                              whiteSpace: 'pre-wrap',
                              borderRadius: '30px',
                              lineHeight: '1.5',
                              fontSize: '0.875rem',
                              '--scrollbar-thumb-color': textColor, // **Added CSS Variable**
                            }}
                          />
                          <div
                            style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
                          >
                            <button
                              onClick={handleCancelEdit}
                              style={{
                                backgroundColor: isDarkMode ? '#1E1E1E' : '#ffffff',
                                color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                                borderRadius: '20px',
                                padding: '5px 7.5px',
                                border: 'none',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                outline: 'none',
                                fontSize: '0.875rem',
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleSaveEdit}
                              style={{
                                backgroundColor: isDarkMode ? '#1E1E1E' : '#ffffff',
                                color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                                borderRadius: '20px',
                                padding: '5px 7.5px',
                                border: 'none',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                outline: 'none',
                                fontSize: '0.875rem',
                              }}
                            >
                              Send
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {msg.sender === 'bot' &&
                            msg.text !== 'loading' &&
                            index === latestBotMessageIndex && (
                              <>
                                <RefreshIcon
                                  className={`refresh-icon ${
                                    isDarkMode ? 'dark-mode' : 'light-mode'
                                  }`}
                                  onClick={handleRefresh}
                                  style={{
                                    cursor: 'pointer',
                                    width: '17.5px',
                                    height: '17.5px',
                                    position: 'absolute',
                                    bottom: '-35px',
                                    left: '10px',
                                    transform: 'translateY(-50%)',
                                    color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                                  }}
                                />
                                <ThumbUpIcon
                                  className={`thumb-up-icon ${
                                    isDarkMode ? 'dark-mode' : 'light-mode'
                                  }`}
                                  style={{
                                    cursor: 'pointer',
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    bottom: '-37px',
                                    left: '35px',
                                    transform: 'translateY(-50%)',
                                    color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                                  }}
                                />
                                <ThumbDownIcon
                                  className={`thumb-down-icon ${
                                    isDarkMode ? 'dark-mode' : 'light-mode'
                                  }`}
                                  style={{
                                    cursor: 'pointer',
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    bottom: '-37px',
                                    left: '60px',
                                    transform: 'translateY(-50%)',
                                    color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                                  }}
                                />
                              </>
                            )}
                          {msg.text !== 'loading' && (
                            <div style={{ whiteSpace: 'pre-wrap' }}>{msg.text}</div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Chat Input */}
          <div
            className="chat-input-flex-container"
            style={{
              display: 'flex', // Ensure it's a flex container
              justifyContent: 'center',
              alignItems: 'flex-end', // Align items to the bottom
              paddingLeft: isNewButtonClicked ? '223px' : '25px',
              paddingRight: isNewButtonClicked ? '25px' : '25px',
              boxSizing: 'border-box',
              flexShrink: 0,
              paddingBottom: isNewButtonClicked ? '5px' : '5px',
            }}
          >
            <div
              className="chat-input-wrapper"
              style={{
                flexGrow: 1,
                flexShrink: 0,
                flexBasis: isNewButtonClicked ? '370px' : '100%',
                minWidth: isNewButtonClicked ? '370px' : 'auto',
                maxWidth: isNewButtonClicked ? '650px' : '100%',
                boxSizing: 'border-box',
                backgroundColor: isDarkMode ? '#1E1E1E' : '#ffffff',
                borderRadius: '1.5rem',
                position: 'relative',
                zIndex: isNewButtonClicked ? zIndices.chat + 5 : 9999,
                postition: isNewButtonClicked ? 'fixed' : 'auto',
                transition: 'flex-basis 0.3s ease, max-width 0.3s ease, min-width 0.3s ease',
                maxHeight: '175px', // Ensure the wrapper does not exceed 175px
                overflow: 'hidden', // Prevents expansion beyond max height
                outline: `2.5px solid ${isDarkMode ? '#1E1E1E' : '#ffffff'}`,
                flexDirection: 'column',
              }}
            >
              <div
                className="input-two"
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  minWidth: isNewButtonClicked ? '100%' : '100%',
                  maxWidth: isNewButtonClicked ? '650px' : '100%',
                  boxSizing: 'border-box',
                  backgroundColor: isDarkMode ? '#1E1E1E' : '#ffffff',
                  borderRadius: '1.5rem',
                  transition: 'flex-basis 0.3s ease, max-width 0.3s ease, min-width 0.3s ease',
                  maxHeight: '150px', // Ensure the wrapper does not exceed 150px
                  overflow: 'hidden', // Prevents expansion beyond max height
                }}
              >
                <form
                  key={`${isDarkMode}-${primaryColor}`}
                  onSubmit={handleSubmit}
                  className="chat-input"
                  style={{
                    borderRadius: '20px',
                    width: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    alignItems: 'flex-end', // Align items to the bottom
                    maxHeight: '150px', // Ensure the form does not exceed 150px
                    overflow: 'hidden', // Prevents expansion beyond max height
                  }}
                >
                  <textarea
                    ref={sendTextareaRef} // **Assigned sendTextareaRef**
                    className="send-textarea"
                    name="message" // **Assigned name attribute**
                    value={userInput}
                    onChange={(e) => {
                      setUserInput(e.target.value);
                      e.target.style.height = 'auto';
                      const newHeight = Math.min(e.target.scrollHeight, 130); // Cap height to 130px
                      e.target.style.height = `${newHeight}px`;
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Message Athena"
                    rows="1"
                    style={{
                      width: '100%',
                      resize: 'none',
                      overflow: 'hidden',
                      overflowY: 'auto', // Enable vertical scrolling
                      whiteSpace: 'pre-wrap',
                      backgroundColor: 'transparent',
                      color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                      border: 'none',
                      outline: 'none',
                      flexGrow: 1,
                      padding: '10px 24px',
                      borderRadius: '30px',
                      fontSize: '0.875rem',
                      maxHeight: '130px', // Ensure the textarea does not exceed 130px
                    }}
                  />
                </form>
              </div>
              <SendButton userInput={userInput} sendMessage={sendMessage} />
            </div>
          </div>

          {/* **Added Message Beneath Chat Input Wrapper** */}
          <div
            style={{
              textAlign: 'center',
              color: isDarkMode ? '#1E1E1E' : '#1E1E1E',
              //marginTop: '5px',
              marginLeft: isNewButtonClicked ? '238px': '0',
              fontSize: '0.65rem',
              padding: '0 25px',
              boxSizing: 'border-box',
            }}
          >
            drop dept workspace chats are used to train our models. Athena can make mistakes.
          </div>
        </div>
      )}

      {/* Conditionally Render Down Component Outside chat-container when isNewButtonClicked is true */}
      {isChatOpen && isNewButtonClicked && (
        <Down isOpen={isDownOpen} onClose={() => setIsDownOpen(false)} />
      )}

      {/* Consent UI */}
      {showConsent && <ConsentUI onAgree={handleConsentAgree} />}

      {/* Options Component */}
      {isChatOpen && (
        <Options
          isOptionsOpen={isOptionsOpen}
          toggleOptions={toggleOptions}
          chatPosition={chatPosition}
          chatButtonRealTop={storedChatButtonTop}
          chatButtonRealBottom={storedChatButtonBottom}
          chatButtonRealLeft={storedChatButtonLeft}
          chatButtonRealRight={storedChatButtonRight}
          chatButtonRealWidth={storedChatButtonWidth}
          chatButtonRealHeight={chatRealHeight}
          chatWidth={chatWidth}
          updateOptionsDimensions={handleUpdateOptionsDimensions}
          chatRealBottom={chatRealBottom}
          chatRealRight={chatRealRight}
          chatRealWidth={chatRealWidth}
          chatRealHeight={chatRealHeight}
          buttonRef={chatButtonRef}
          isNewButtonClicked={isNewButtonClicked}
        />
      )}

      {/* Hidden Chat Button Reference */}
      <div
        ref={chatButtonRef}
        style={{ position: 'fixed', bottom: '20px', right: '20px', opacity: 0 }}
      />
    </>
  );
};

export default Chat;
