// ThemeContext.js

import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const initialColor = '#007bff'; // Default color (blue)

  // Retrieve persisted states from localStorage or set default values
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const stored = localStorage.getItem('isDarkMode');
    return stored ? JSON.parse(stored) : false;
  });

  const [primaryColor, setPrimaryColor] = useState(() => {
    return localStorage.getItem('primaryColor') || initialColor;
  });

  const [isUserToggled, setIsUserToggled] = useState(false); // Track if the user manually changed the theme

  // State to manage visibility and settings
  const [isOptionsOpen, setIsOptionsOpen] = useState(() => {
    const stored = localStorage.getItem('isOptionsOpen');
    return stored ? JSON.parse(stored) : false;
  });

  const [isSettingsOpen, setIsSettingsOpen] = useState(() => {
    const stored = localStorage.getItem('isSettingsOpen');
    return stored ? JSON.parse(stored) : false;
  });

  const [isFlappyBirdOpen, setIsFlappyBirdOpen] = useState(() => {
    const stored = localStorage.getItem('isFlappyBirdOpen');
    return stored ? JSON.parse(stored) : false;
  });

  // Force isChatOpen to always be true
  const [isChatOpen, setIsChatOpen] = useState(true); // Initialize as true, ignoring localStorage

  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(() => {
    const stored = localStorage.getItem('isMobileHeaderOpen');
    return stored ? JSON.parse(stored) : false;
  });

  const [isChatButtonVisible, setIsChatButtonVisible] = useState(() => {
    const stored = localStorage.getItem('isChatButtonVisible');
    return stored ? JSON.parse(stored) : true;
  });

  // New State Variable for the New Button
  const [isNewButtonClicked, setIsNewButtonClicked] = useState(() => {
    const stored = localStorage.getItem('isNewButtonClicked');
    return stored ? JSON.parse(stored) : false;
  });

  const [isChatButtonClicked, setIsChatButtonClicked] = useState(() => {
    const stored = localStorage.getItem('isChatButtonClicked');
    return stored ? JSON.parse(stored) : false;
  });

  const [chatButtonMetrics, setChatButtonMetrics] = useState({
    chatButtonRealTop: 0,
    chatButtonRealBottom: 20,
    chatButtonRealLeft: 0,
    chatButtonRealRight: 20,
    chatButtonRealWidth: 0,
    chatButtonRealHeight: 0,
  });

  const [chatMetrics, setChatMetrics] = useState({
    chatRealBottom: 0,
    chatRealRight: 0,
    chatRealWidth: 0,
    chatRealHeight: 0,
  });

  const [chatBottomPosition, setChatBottomPosition] = useState('0px');

  const zIndices = {
    chat: 999,
    options: 1000,      // Adjusted options zIndex to be less than chat
    settings: 1002,
    flappyBird: 1003,
    closeFlappyBird: 1004,
  };

  // Helper function to log and send postMessage
  const logAndPostMessage = (message) => {
    console.log('Sending postMessage:', message); // Log message being passed
    window.parent.postMessage(message, '*');      // Send postMessage to parent window
  };

  // Added clickedOnce state (reset on page reload)
  const [clickedOnce, setClickedOnce] = useState(false); // Initialize to false on every reload

  // Added notifyClick function
  const notifyClick = () => {
    console.log("ThemeContext: notifyClick called.");
    if (!clickedOnce) {
      setClickedOnce(true);
      console.log("ThemeContext: clickedOnce set to true.");
      // Any additional logic on the first click can be added here
    }
  };

  // Persist state changes to localStorage (excluding clickedOnce)
  useEffect(() => {
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
    console.log(`Persisted isDarkMode: ${isDarkMode}`);
  }, [isDarkMode]);

  useEffect(() => {
    localStorage.setItem('primaryColor', primaryColor);
    console.log(`Persisted primaryColor: ${primaryColor}`);
  }, [primaryColor]);

  useEffect(() => {
    localStorage.setItem('isOptionsOpen', JSON.stringify(isOptionsOpen));
    console.log(`Persisted isOptionsOpen: ${isOptionsOpen}`);
  }, [isOptionsOpen]);

  useEffect(() => {
    localStorage.setItem('isChatOpen', JSON.stringify(isChatOpen));
    console.log(`Persisted isChatOpen: ${isChatOpen}`);
  }, [isChatOpen]);

  useEffect(() => {
    localStorage.setItem('isChatButtonVisible', JSON.stringify(isChatButtonVisible));
    console.log(`Persisted isChatButtonVisible: ${isChatButtonVisible}`);
  }, [isChatButtonVisible]);

  useEffect(() => {
    localStorage.setItem('isSettingsOpen', JSON.stringify(isSettingsOpen));
    console.log(`Persisted isSettingsOpen: ${isSettingsOpen}`);
  }, [isSettingsOpen]);

  useEffect(() => {
    localStorage.setItem('isFlappyBirdOpen', JSON.stringify(isFlappyBirdOpen));
    console.log(`Persisted isFlappyBirdOpen: ${isFlappyBirdOpen}`);
  }, [isFlappyBirdOpen]);

  useEffect(() => {
    localStorage.setItem('isNewButtonClicked', JSON.stringify(isNewButtonClicked));
    console.log(`Persisted isNewButtonClicked: ${isNewButtonClicked}`);
  }, [isNewButtonClicked]);

  useEffect(() => {
    localStorage.setItem('isChatButtonClicked', JSON.stringify(isChatButtonClicked));
    console.log(`Persisted isChatButtonClicked: ${isChatButtonClicked}`);
  }, [isChatButtonClicked]);

  // Auto-switch theme at 6 AM and 6 PM
  useEffect(() => {
    const checkTimeAndUpdateTheme = () => {
      const now = new Date();
      const hour = now.getHours();

      if (!isUserToggled) { // Only auto-update if the user has not manually toggled
        if (hour === 6 && isDarkMode) {
          setIsDarkMode(false); // Switch to light mode at 6 AM
          logAndPostMessage({ isDarkMode: false });
          console.log('Switched to light mode at 6 AM');
        } else if (hour === 18 && !isDarkMode) {
          setIsDarkMode(true); // Switch to dark mode at 6 PM
          logAndPostMessage({ isDarkMode: true });
          console.log('Switched to dark mode at 6 PM');
        }
      }
    };

    checkTimeAndUpdateTheme(); // Initial check

    const intervalId = setInterval(checkTimeAndUpdateTheme, 60000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [isDarkMode, isUserToggled]);

  // Inject styles into the document
  useEffect(() => {
    const style = document.createElement('style');
    const backgroundColor = isOptionsOpen && isChatOpen ? 'transparent' : isDarkMode ? '#000000' : '#ffffff';
    const boxShadow = isOptionsOpen && isChatOpen ? 'none' : '0 8px 20px rgba(0, 0, 0, 0.2)';

    style.innerHTML = `
      .chat-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        border: none;
        cursor: pointer;
        z-index: 1010;
        box-shadow: ${boxShadow};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: background-color 0.3s ease, transform 0.3s ease, width 0.3s ease;
        background-color: ${backgroundColor};
      }
      .chat-button.light-mode {
        background-color: #ffffff;
        color: #333;
      }
      .chat-button.light-mode:hover {
        background-color: ${isOptionsOpen ? '#ffffff' : 'rgba(0, 0, 0, 0.1)'};
      }
      .chat-button.dark-mode {
        background-color: #000000;
        color: #ffffff;
      }
      .chat-button.dark-mode:hover {
        background-color: ${isOptionsOpen ? '#000000' : '#2c2c2e'};
      }
      .chat-button-logo {
        width: 80%;
        height: 80%;
        object-fit: contain;
        border-radius: inherit;
      }
      .chat-button.expanded {
        width: 100%;
        border-radius: 10px;
        right: 0;
        bottom: 0;
      }
    `;
    document.head.appendChild(style);
    console.log('Injected chat-button styles into the document');

    return () => {
      document.head.removeChild(style);
      console.log('Removed chat-button styles from the document');
    };
  }, [isDarkMode, primaryColor, isOptionsOpen, isChatOpen]);

  // Compute chat bottom position
  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    const bottomPosition = isMobile
      ? '10vh'
      : `${chatButtonMetrics.chatButtonRealTop}px`;

    setChatBottomPosition(bottomPosition);
    console.log('Computed Chat Bottom Position:', bottomPosition);
  }, [isOptionsOpen, chatButtonMetrics]);

  // Toggle Theme
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      console.log(`Toggling theme to ${newMode ? 'dark' : 'light'} mode`);
      setIsUserToggled(true); // User has manually toggled the theme
      logAndPostMessage({ isDarkMode: newMode });
      return newMode;
    });
  };

  // Update primary color and trigger effects
  const updatePrimaryColor = (newColor) => {
    setPrimaryColor(newColor);
    console.log(`Updating primaryColor to ${newColor}`);
    logAndPostMessage({ primaryColor: newColor });
  };

  // Reset primary color to its initial state
  const resetPrimaryColor = () => {
    setPrimaryColor(initialColor);
    console.log(`Resetting primaryColor to initial value: ${initialColor}`);
    logAndPostMessage({ primaryColor: initialColor });
  };

  const toggleChatButtonVisibility = () => {
    setIsChatButtonVisible((prevVisible) => {
      const newVisibility = !prevVisible;
      console.log(`Toggling chat button visibility to ${newVisibility}`);
      logAndPostMessage({ isChatButtonVisible: newVisibility });
      return newVisibility;
    });
  };

  // Function to toggle the Options open state
  const toggleOptionsOpen = () => {
    if (!isChatOpen) {
      console.warn('Cannot toggle options because chat is not open');
      return;
    }

    setIsOptionsOpen((prevOpen) => {
      const newIsOpen = !prevOpen;
      console.log(`Toggling isOptionsOpen to ${newIsOpen}`);
      logAndPostMessage({ isOptionsOpen: newIsOpen });

      if (newIsOpen) {
        document.documentElement.style.setProperty('--chat-button-background', 'transparent');
        document.documentElement.style.setProperty('--chat-button-box-shadow', 'none');
        console.log('Options opened: Background set to transparent, box-shadow removed');
      } else {
        const backgroundColor = isDarkMode ? '#000000' : primaryColor;
        document.documentElement.style.setProperty('--chat-button-background', backgroundColor);
        document.documentElement.style.setProperty('--chat-button-box-shadow', '0 8px 20px rgba(0, 0, 0, 0.2)');
        console.log(`Options closed: Background set to ${backgroundColor}, box-shadow applied`);
      }

      return newIsOpen;
    });
  };

  // Toggle Settings and FlappyBird states
  const toggleSettingsOpen = () => { // Removed forceOpen parameter
    setIsSettingsOpen((prevOpen) => {
      const newIsSettingsOpen = !prevOpen;
      console.log(`Toggling isSettingsOpen to ${newIsSettingsOpen}`);
      logAndPostMessage({ isSettingsOpen: newIsSettingsOpen });
      return newIsSettingsOpen;
    });
  };

  const toggleFlappyBirdOpen = () => {
    setIsFlappyBirdOpen((prevOpen) => {
      const newIsFlappyBirdOpen = !prevOpen;
      console.log(`Toggling isFlappyBirdOpen to ${newIsFlappyBirdOpen}`);
      logAndPostMessage({ isFlappyBirdOpen: newIsFlappyBirdOpen });
      return newIsFlappyBirdOpen;
    });
  };

  // Remove toggle functionality for isChatOpen to enforce always open
  // Instead, handle chat opening without allowing it to close
  const toggleNewButton = () => {
    setIsNewButtonClicked((prevState) => {
      const newState = !prevState;
      console.log(`Toggling isNewButtonClicked to ${newState}`);
      logAndPostMessage({ isNewButtonClicked: newState });

      // Removed any synchronization with isSettingsOpen
      return newState;
    });
  };

  // Modify handleChatButtonClick to prevent closing the chat
  const handleChatButtonClick = () => {
    setIsChatButtonClicked(true);
    // Ensure isChatOpen remains true
    if (!isChatOpen) {
      setIsChatOpen(true);
      console.log(`Setting isChatOpen to true`);
      logAndPostMessage({ isChatOpen: true });
    }
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setIsMobileHeaderOpen((prev) => {
        const newIsMobileHeaderOpen = !prev;
        console.log(`Toggling isMobileHeaderOpen to ${newIsMobileHeaderOpen}`);
        return newIsMobileHeaderOpen;
      });
    }
  };

  // Update chat button metrics
  const updateChatButtonMetrics = (metrics) => {
    console.log('Updating chatButtonMetrics:', metrics);
    setChatButtonMetrics(metrics);
    logAndPostMessage({ chatButtonMetrics: metrics });
  };

  // Update chat metrics only if the chat button is clicked
  const updateChatMetrics = (metrics) => {
    if (isChatButtonClicked) {
      console.log('Updating chatMetrics:', metrics);
      setChatMetrics(metrics);
      logAndPostMessage({ chatMetrics: metrics });
    } else {
      console.log('ChatButton not clicked yet, skipping chat metrics update.');
    }
  };

  // Persist state changes to localStorage for chat button clicked
  useEffect(() => {
    localStorage.setItem('isChatButtonClicked', JSON.stringify(isChatButtonClicked));
    console.log(`Persisted isChatButtonClicked: ${isChatButtonClicked}`);
  }, [isChatButtonClicked]);

  // Log chat button metrics updates
  useEffect(() => {
    console.log('Chat button metrics updated:', chatButtonMetrics);
    logAndPostMessage({ chatButtonMetrics });
  }, [chatButtonMetrics]);

  // Log chat metrics updates
  useEffect(() => {
    console.log('Chat metrics updated:', chatMetrics);
    logAndPostMessage({ chatMetrics });
  }, [chatMetrics]);

  // Log and send postMessage on state changes
  useEffect(() => {
    console.log(`isOptionsOpen state updated in ThemeContext: ${isOptionsOpen}`);
    logAndPostMessage({ isOptionsOpen });
  }, [isOptionsOpen]);

  useEffect(() => {
    console.log(`isDarkMode state updated in ThemeContext: ${isDarkMode}`);
    logAndPostMessage({ isDarkMode });
  }, [isDarkMode]);

  useEffect(() => {
    console.log(`primaryColor state updated in ThemeContext: ${primaryColor}`);
    logAndPostMessage({ primaryColor });
  }, [primaryColor]);

  useEffect(() => {
    console.log(`isChatOpen state updated in ThemeContext: ${isChatOpen}`);
    logAndPostMessage({ isChatOpen }); // Always sends true
  }, [isChatOpen]);

  useEffect(() => {
    console.log(`isNewButtonClicked state updated in ThemeContext: ${isNewButtonClicked}`);
    logAndPostMessage({ isNewButtonClicked });
  }, [isNewButtonClicked]);

  // Handle incoming messages from parent window
  useEffect(() => {
    const handleParentMessage = (event) => {
      const allowedOrigins = ['https://dropdept.com', 'https://thriving-haupia-829bfb.netlify.app']; // Add your origins here
      console.log(`Received message from origin: ${event.origin}`, event.data);
      if (!allowedOrigins.includes(event.origin)) {
        console.warn(`Received message from unauthorized origin: ${event.origin}`);
        return;
      }

      if (typeof event.data.isOptionsOpen === 'boolean') {
        setIsOptionsOpen(event.data.isOptionsOpen);
        console.log('isOptionsOpen state updated from parent:', event.data.isOptionsOpen);
      }

      if (typeof event.data.isChatOpen === 'boolean') {
        // Ignore attempts to set isChatOpen to false
        if (event.data.isChatOpen) {
          setIsChatOpen(true);
          console.log('isChatOpen state updated to true from parent:', event.data.isChatOpen);
        } else {
          console.warn('Attempted to set isChatOpen to false, which is ignored to keep chat always open.');
          // Optionally, you can re-send the state to parent to enforce it
          logAndPostMessage({ isChatOpen: true });
        }
      }

      if (typeof event.data.isDarkMode === 'boolean') {
        setIsDarkMode(event.data.isDarkMode);
        console.log('isDarkMode state updated from parent:', event.data.isDarkMode);
      }

      if (event.data.primaryColor) {
        setPrimaryColor(event.data.primaryColor);
        console.log('primaryColor updated from parent:', event.data.primaryColor);
      }

      if (typeof event.data.isNewButtonClicked === 'boolean') {
        setIsNewButtonClicked(event.data.isNewButtonClicked);
        console.log('isNewButtonClicked state updated from parent:', event.data.isNewButtonClicked);
      }

      if (typeof event.data.isChatButtonClicked === 'boolean') {
        setIsChatButtonClicked(event.data.isChatButtonClicked);
        console.log('isChatButtonClicked state updated from parent:', event.data.isChatButtonClicked);
      }

      if (typeof event.data.clickedOnce === 'boolean') {
        setClickedOnce(event.data.clickedOnce);
        console.log('clickedOnce state updated from parent:', event.data.clickedOnce);
      }
    };

    window.addEventListener('message', handleParentMessage);

    console.log('ThemeProvider: Added event listener for parent messages');

    return () => {
      window.removeEventListener('message', handleParentMessage);
      console.log('ThemeProvider: Removed event listener for parent messages');
    };
  }, []);

  // --------------------- New Global Variables: inputWidth and inputWidthRight ---------------------

  // State variable for inputWidth
  const [inputWidth, setInputWidth] = useState(() => {
    const viewportWidth = window.innerWidth;
    const leftPadding = 162.5; // 275px column + 25px padding
    const rightPadding = 25; // Fixed right padding
    const calculatedWidth = viewportWidth - leftPadding - rightPadding;
    console.log(`Initial inputWidth: ${calculatedWidth}px`);
    return calculatedWidth;
  });

  // State variable for inputWidthRight
  const [inputWidthRight, setInputWidthRight] = useState(25); // Fixed right padding

  // Effect to handle window resize and update inputWidth and inputWidthRight
  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const leftPadding = 162.5; // 275px column + 25px padding
      const rightPadding = 25; // Fixed right padding
      const newInputWidth = viewportWidth - leftPadding - rightPadding;
      setInputWidth(newInputWidth);
      console.log(`Updated inputWidth on resize: ${newInputWidth}px`);

      // Log inputWidthRight if needed (it's fixed)
      console.log(`inputWidthRight remains: ${inputWidthRight}px`);
    };

    window.addEventListener('resize', handleResize);

    // Initial calculation
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      console.log('Removed resize event listener for inputWidth and inputWidthRight');
    };
  }, [inputWidthRight]);

  // --------------------- Preserve Existing inputWidthLeft ---------------------

  // State variable for inputWidthLeft with initial calculation
  const [inputWidthLeft, setInputWidthLeft] = useState(() => {
    const calculatedWidth = window.innerWidth * 0.33; // 33vw in pixels
    const finalWidth = Math.max(300, calculatedWidth);
    console.log(`Calculated inputWidthLeft: ${finalWidth}px`);
    return finalWidth;
  });

  // Effect to handle window resize and update inputWidthLeft
  useEffect(() => {
    const handleResize = () => {
      const calculatedWidth = window.innerWidth * 0.33; // 33vw in pixels
      const finalWidth = Math.max(300, calculatedWidth);
      setInputWidthLeft(finalWidth);
      console.log(`Updated inputWidthLeft on resize: ${finalWidth}px`);
    };

    window.addEventListener('resize', handleResize);

    // Initial calculation
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      console.log('Removed resize event listener for inputWidthLeft');
    };
  }, []);

  // --------------------------------------------------------------------------------

  // --------------------- Addition: Viewport Width and Height ---------------------

  // State variables for viewport width and height
  const [viewportWidth, setViewportWidth] = useState(() => {
    const width = window.innerWidth;
    console.log(`Initial viewportWidth: ${width}px`);
    return width;
  });

  const [viewportHeight, setViewportHeight] = useState(() => {
    const height = window.innerHeight;
    console.log(`Initial viewportHeight: ${height}px`);
    return height;
  });

  // Effect to handle window resize and update viewport width and height
  useEffect(() => {
    const handleViewportResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setViewportWidth(width);
      setViewportHeight(height);
      console.log(`Viewport resized: width=${width}px, height=${height}px`);

      // Update minViewportDimension whenever viewportWidth or viewportHeight changes
      const newMinDimension = Math.min(width, height);
      setMinViewportDimension(newMinDimension);
      console.log(`Updated minViewportDimension: ${newMinDimension}px`);
    };

    window.addEventListener('resize', handleViewportResize);

    // Initial log
    handleViewportResize();

    return () => {
      window.removeEventListener('resize', handleViewportResize);
      console.log('Removed resize event listener for viewport dimensions');
    };
  }, []);

  // --------------------------------------------------------------------------------

  // --------------------- New Global Variable: minViewportDimension ---------------------

  // State variable for minViewportDimension
  const [minViewportDimension, setMinViewportDimension] = useState(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const minDimension = Math.min(width, height);
    console.log(`Initial minViewportDimension: ${minDimension}px`);
    return minDimension;
  });

  // --------------------------------------------------------------------------------

  // --------------------- New Effect to Update CSS Variable ---------------------
  // Update CSS variable based on isNewButtonClicked
  useEffect(() => {
    document.documentElement.style.setProperty('--is-new-button-clicked', isNewButtonClicked ? 'true' : 'false');
    console.log(`Updated CSS variable --is-new-button-clicked to ${isNewButtonClicked}`);
  }, [isNewButtonClicked]);
  // --------------------------------------------------------------------------------

  // --------------------- New Effect to Define --da-baby CSS Variable ---------------------
  // Define the --da-baby CSS variable based on primaryColor and isDarkMode
  useEffect(() => {
    if (primaryColor.toLowerCase() === initialColor.toLowerCase()) {
      if (!isDarkMode) {
        document.documentElement.style.setProperty('--da-baby', '#000000');
        console.log('Set --da-baby to #000000 (original blue and light mode)');
      } else {
        document.documentElement.style.setProperty('--da-baby', '#1e1e1e');
        console.log('Set --da-baby to #1e1e1e (original blue and dark mode)');
      }
    } else {
      document.documentElement.style.setProperty('--da-baby', primaryColor);
      console.log(`Set --da-baby to primaryColor: ${primaryColor}`);
    }
  }, [primaryColor, isDarkMode, initialColor]);
  // --------------------------------------------------------------------------------

  return (
    <ThemeContext.Provider value={{
      isDarkMode,
      toggleTheme,
      primaryColor,
      updatePrimaryColor,
      resetPrimaryColor,
      zIndices,
      isChatButtonVisible,
      toggleChatButtonVisibility,
      isOptionsOpen,
      toggleOptionsOpen,
      isSettingsOpen,
      toggleSettingsOpen, // Updated to the new toggleSettingsOpen without forceOpen
      isFlappyBirdOpen,
      toggleFlappyBirdOpen,
      setChatButtonMetrics: updateChatButtonMetrics,
      setChatMetrics: updateChatMetrics,
      chatButtonMetrics,
      chatMetrics,
      handleChatButtonClick,
      isChatOpen,
      setIsChatOpen,
      chatBottomPosition,
      isMobileHeaderOpen,
      setIsMobileHeaderOpen,
      // New Button State and Toggle Function
      isNewButtonClicked,
      toggleNewButton, // Now only toggles isNewButtonClicked without affecting isSettingsOpen
      // Exposed clickedOnce and notifyClick function
      clickedOnce,
      notifyClick,
      // ------------------- New Global Variables -------------------
      inputWidth,        // Expose inputWidth
      inputWidthRight,   // Expose inputWidthRight
      inputWidthLeft,    // Preserve and expose inputWidthLeft
      // Addition: Viewport dimensions
      viewportWidth,     // Expose viewportWidth
      viewportHeight,    // Expose viewportHeight
      // New Global Variable
      minViewportDimension, // Expose minViewportDimension
      // --------------------------------------------------------------------------------
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
