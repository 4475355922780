import React, { useState, useContext, useEffect, useRef, forwardRef } from 'react';
import { ThemeContext } from './ThemeContext.js';
import Chat from './Chat.js';
import './ChatButton.css';
import { ReactComponent as Logo } from './logo.svg'; // Importing as React component

// Function to calculate luminance
const getLuminance = (hex) => {
  const rgb = parseInt(hex.slice(1), 16); 
  const r = (rgb >> 16) & 255;
  const g = (rgb >> 8) & 255;
  const b = rgb & 255;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

// Function to determine appropriate text color based on luminance
const adjustTextColor = (primaryColor) => {
  const luminance = getLuminance(primaryColor);
  return luminance > 128 ? '#000000' : '#FFFFFF'; // Light or dark text bsed on luminance
};

// Function to detect if the device is mobile
const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);

// Function to get background color with additional logic for specific colors (like blue)
const getBackgroundColor = (primaryColor, isDarkMode) => {
  if (primaryColor === '#007bff') {
    return isDarkMode ? '#555555' : '#000000'; // Special handling for blue
  }
  return primaryColor;
};

const ChatButton = forwardRef((props, ref) => {
  // Use states from ThemeContext
  const { isDarkMode, primaryColor, isChatButtonVisible, isOptionsOpen, setIsChatOpen } = useContext(ThemeContext);
  const buttonRef = useRef(null);
  const [chatButtonRealTop, setChatButtonRealTop] = useState(0);
  const [chatButtonRealBottom, setChatButtonRealBottom] = useState(20); // Fixed based on CSS

  const openChat = () => {
    console.log('openChat called');
    setIsChatOpen(true); // Ensure chat is always open
  };

  const closeChat = () => {
    console.log('closeChat called, but preventing close');
    setIsChatOpen(true); // Prevent chat from being closed
  };

  // Ensure the chat is always open whenever the component mounts
  useEffect(() => {
    setIsChatOpen(true); // Keep chat open
  }, [setIsChatOpen]);

  React.useImperativeHandle(ref, () => ({
    openChat,
    closeChat,
  }));

  // Add event listener for messages from the parent window
  useEffect(() => {
    function handleMessage(event) {
      // Verify the origin of the message for security
      if (event.origin !== 'https://dropdept.com') return; // Use your Shopify store's domain

      if (event.data === 'toggleChat') {
        console.log('Received toggleChat message from parent');
        setIsChatOpen(true); // Always open chat when receiving toggle command
      }
    }

    window.addEventListener('message', handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [setIsChatOpen]);

  // Continuously send updates when `isChatOpen` changes
  useEffect(() => {
    window.parent.postMessage('chatOpened', 'https://dropdept.com');
  }, [isChatButtonVisible]); // Only when button is visible

  // Calculate background color based on primary color and dark mode
  const backgroundColor = getBackgroundColor(primaryColor, isDarkMode);

  // Calculate luminance to determine appropriate logo color
  const luminance = getLuminance(backgroundColor);
  const logoColor = luminance > 128 ? '#3c3c3e' : '#f0f0f5';  // Use luminance logic in light or dark mode

  // Handle resizing and applying button dimensions
  useEffect(() => {
    const setButtonSize = () => {
      const viewportHeight = window.innerHeight;
      const buttonSize = 0.1 * viewportHeight; // 10% of the viewport height for button size

      if (buttonRef.current) {
        buttonRef.current.style.width = `${buttonSize}px`;
        buttonRef.current.style.height = `${buttonSize}px`;
        buttonRef.current.style.borderRadius = '50%'; // Circular shape
        console.log('Button size updated:', buttonSize);
      }
    };

    setButtonSize();
    window.addEventListener('resize', setButtonSize); // Update on resize
    return () => window.removeEventListener('resize', setButtonSize); // Clean up listener
  }, []);

  // Hiding logic
  const chatButtonStyle = {
    bottom: `2.5vw`, // Adjust bottom position
    left: '2.5vw',
    backgroundColor,
    display: isChatButtonVisible ? 'block' : 'none',
    borderRadius: '50%', // Circular shape
    visibility: isMobileDevice() && true ? 'hidden' : 'hidden', // Always open chat
    padding: 'none',
    position: 'fixed', // Ensure correct stacking
    width: '10vh', 
    height: '10vh',
    boxShadow: isMobileDevice() ? 'none' : '0 20px 25px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.10)', // Conditionally remove box shadow on mobile
  };

  return (
    <div>
      <button
        className={`chat-button ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
        ref={buttonRef}
        style={chatButtonStyle}
        onClick={openChat} // Always open chat on click
      >
        {/* Background Circle */}
        <div
          className="chat-button-background-circle"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%', 
            height: '100%', 
            borderRadius: '50%',
            boxShadow: '0 20px 25px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.10)', // Box shadow for background circle
          }}
        ></div>

        {/* Logo */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
          }}
        >
          <Logo 
            style={{ 
              width: '100%', 
              height: '100%', 
              objectFit: 'contain',
              color: logoColor,  // Apply the correct logo color
            }} 
          />
        </div>
      </button>
      <Chat 
        isChatOpen={true} // Pass `true` to keep the chat always open
        chatButtonRealTop={chatButtonRealTop}
        chatButtonRealBottom={chatButtonRealBottom}
        chatButtonRealWidth={'10vh'} // Pass fixed width and height
        chatButtonRealHeight={'10vh'}
      />
    </div>
  );
});

export default ChatButton;
