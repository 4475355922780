import React, { useEffect, useRef, useContext, useState, useLayoutEffect } from 'react';
import { ThemeContext } from './ThemeContext.js';
import { ReactComponent as ReviewIcon } from './review.svg';
import { ReactComponent as FeedbackIcon } from './feedback.svg';
import { ReactComponent as ReportIcon } from './report.svg';
import { ReactComponent as AthenaIcon } from './athena.svg';
import { ReactComponent as SirenIcon } from './siren.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as CollapseIcon } from './collapse.svg';

// **Added Imports for New Icons**
import { ReactComponent as ProfileIcon } from './profile.svg';
import { ReactComponent as MailIcon } from './mail.svg';
import { ReactComponent as ListIcon } from './list.svg';
import { ReactComponent as MemoryIcon } from './memory.svg';

const ORIGINAL_BLUE = '#007bff';

// Utility functions
const getBackgroundColor = (primaryColor, isDarkMode) => {
  if (primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()) {
    return isDarkMode ? '#1E1E1E' : '#000000';
  }
  return primaryColor;
};

const getLuminance = (hex) => {
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 255;
  const g = (rgb >> 8) & 255;
  const b = rgb & 255;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const adjustTextColor = (backgroundColor) => {
  const luminance = getLuminance(backgroundColor);
  return luminance > 128 ? '#1E1E1E' : '#f0f0f5';
};

const darkenHexColor = (hex, amount = 10) => {
  hex = hex.replace('#', '');
  let r = Math.max(0, parseInt(hex.substring(0, 2), 16) - amount);
  let g = Math.max(0, parseInt(hex.substring(2, 4), 16) - amount);
  let b = Math.max(0, parseInt(hex.substring(4, 6), 16) - amount);
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const lightenHexColor = (hex, amount = 10) => {
  hex = hex.replace('#', '');
  let r = Math.min(255, parseInt(hex.substring(0, 2), 16) + amount);
  let g = Math.min(255, parseInt(hex.substring(2, 4), 16) + amount);
  let b = Math.min(255, parseInt(hex.substring(4, 6), 16) + amount);
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const Down = ({ isOpen, onClose }) => {
  const { isDarkMode, primaryColor, zIndices, isNewButtonClicked, toggleNewButton } = useContext(ThemeContext);
  const downRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const buttonRefs = useRef([]);
  const [maxButtonWidth, setMaxButtonWidth] = useState(null);

  // State to manage hover effects on buttons
  const [hoveredButton, setHoveredButton] = useState(null);

  // New States for CloseIcon and CollapseIcon Hover Effects
  const [hoveredClose, setHoveredClose] = useState(false);
  const [hoveredCollapse, setHoveredCollapse] = useState(false); // Collapse Hover State

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  // New variable 'velma' Definition
  const velma = isDarkMode ? '#1e1e1e' : '#ffffff';

  // Determine backgroundColor based on isNewButtonClicked
  const backgroundColor = isNewButtonClicked ? velma : getBackgroundColor(primaryColor, isDarkMode);

  // Updated textColor calculation based on backgroundColor
  const textColor = adjustTextColor(backgroundColor);

  // Calculate borderColor based on backgroundColor and primaryColor
  const borderColor =
    primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 25)
      : darkenHexColor(backgroundColor, 35);

  // Compute Hover Styles for CloseIcon and CollapseIcon based on backgroundColor
  const closeHoverBackgroundColor =
    isNewButtonClicked && !isDarkMode
      ? '#f0f0f5' // New condition for light mode
      : isDarkMode && isNewButtonClicked
      ? '#4B4B4B' // 20% lighter than '#1e1e1e'
      : primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 25)
      : darkenHexColor(backgroundColor, 35);

  const collapseHoverBackgroundColor =
    isNewButtonClicked && !isDarkMode
      ? '#f0f0f5' // New condition for light mode
      : isDarkMode && isNewButtonClicked
      ? '#4B4B4B' // 20% lighter than '#1e1e1e'
      : primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 25)
      : darkenHexColor(backgroundColor, 35);

  // Hover background color for buttons
  const hoverBackgroundColor =
    !isDarkMode && isNewButtonClicked
      ? '#f0f0f5'
      : isDarkMode && isNewButtonClicked
      ? '#4B4B4B'
      : primaryColor.toLowerCase() === ORIGINAL_BLUE.toLowerCase()
      ? lightenHexColor(backgroundColor, 10)
      : darkenHexColor(backgroundColor, 20);

  const handleClickOutside = (event) => {
    if (isNewButtonClicked) {
      return;
    }
    if (downRef.current && !downRef.current.contains(event.target)) {
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  useEffect(() => {
    if (isOpen || isNewButtonClicked) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isOpen, isNewButtonClicked]);

  // **Updated Buttons Array with New Icons**
  const buttons = [
    { label: 'New Chat', Icon: AthenaIcon },
    { label: 'Swipe', Icon: SirenIcon },
    { label: 'Profile', Icon: ProfileIcon },   // Changed from Logo to ProfileIcon
    { label: 'Wishlist', Icon: ListIcon },     // Changed from Logo to ListIcon
    { label: 'Mailbox', Icon: MailIcon },       // Changed from Logo to MailIcon
    { label: 'Review', Icon: ReviewIcon },
    { label: 'Feedback', Icon: FeedbackIcon },
    { label: 'Memory', Icon: MemoryIcon },
    { label: 'Report', Icon: ReportIcon },
  ];

  const shouldRender = isOpen || isNewButtonClicked;

  useLayoutEffect(() => {
    if (isNewButtonClicked && buttonRefs.current.length > 0) {
      const widths = buttonRefs.current.map((btn) => btn.getBoundingClientRect().width);
      const maxWidth = Math.max(...widths);
      setMaxButtonWidth(maxWidth);
    } else {
      setMaxButtonWidth(null);
    }
  }, [isNewButtonClicked, buttons]);

  // Inject custom scrollbar styles
  useEffect(() => {
    const scrollbarColor = isNewButtonClicked
      ? isDarkMode
        ? '#3c3c3e'
        : '#1e1e1e'
      : borderColor;

    const scrollbarWidth = isNewButtonClicked ? 'auto' : '5px';

    const scrollbarStyles = `
      .button-container::-webkit-scrollbar {
        width: ${scrollbarWidth};
      }
      .button-container::-webkit-scrollbar-thumb {
        background-color: ${scrollbarColor};
        border-radius: 10px;
      }
      .button-container {
        scrollbar-width: ${isNewButtonClicked ? 'auto' : 'thin'};
        scrollbar-color: ${scrollbarColor} transparent;
      }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.id = 'scrollbar-styles';
    styleSheet.innerText = scrollbarStyles;
    document.head.appendChild(styleSheet);

    return () => {
      const existingStyleSheet = document.getElementById('scrollbar-styles');
      if (existingStyleSheet) {
        document.head.removeChild(existingStyleSheet);
      }
    };
  }, [isNewButtonClicked, borderColor, isDarkMode]);

  return shouldRender ? (
    <>
      {/* Reintroduced Top-Right Container */}
      {isNewButtonClicked && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            right: '0',
            width: '100vw',
            height: '101.5px',
            backgroundColor: getBackgroundColor(primaryColor, isDarkMode), // Original backgroundColor
            zIndex: zIndices.chat + 1, // Maintains original zIndex
            paddingTop: '10px',
          }}
        >
          {/* Top-Right Container Restored Without CloseIcon */}
          {/* If there are other elements to include in the top-right container, add them here */}
        </div>
      )}

      {/* Existing Down Container */}
      <div
        className="down-container"
        ref={downRef}
        style={{
          position: isNewButtonClicked ? 'fixed' : 'absolute',
          top: isNewButtonClicked ? '3px' : '125px', // Adjusted top to accommodate Close button if needed
          left: isNewButtonClicked ? '104px' : '40px',
          transform: isNewButtonClicked ? 'translateX(-50%)' : 'none',
          width: isNewButtonClicked ? '208px' : 'max-content',
          height: isNewButtonClicked ? 'calc(100vh - 37.5px)' : 'calc(100vh - 300px)', // Adjust height as needed
          marginTop: isNewButtonClicked ? '32.8px' : '7.5px', // Changed from 'none' to '0'
          marginLeft: isNewButtonClicked ? '0px' : '25px',
          zIndex: isNewButtonClicked ? zIndices.chat + 1 : zIndices.down || 1003,
          // Consolidated borderRadius logic
          borderRadius: isNewButtonClicked
            ? '0px' // Set to 0px when isNewButtonClicked is true
            : isMobile
            ? '1.5vh' // 1.5vh for mobile
            : '2vh', // 2vh otherwise
          overflowY: isNewButtonClicked ? 'auto' : 'auto',
          backgroundColor: backgroundColor, // Replaced with backgroundColor
          color: textColor, // Ensures text uses the calculated textColor
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: isNewButtonClicked ? '0px' : '5px',
          outline: isNewButtonClicked ? `none` : `2px solid ${borderColor}`,
          transition: 'all 0.3s ease',
          whiteSpace: 'nowrap',
          paddingTop: isNewButtonClicked ? '125px' : '0',
          //maxheight: isNewButtonClicked ? 'none' : '400px',
        }}
      >
        {isNewButtonClicked && (
          <div
            style={{
              position: 'absolute',
              top: '20px',
              left: '20px', // Adjusted back to original 'left' position
              display: 'flex',
              zIndex: zIndices.chat + 6,
            }}
          >
            {/* Reordered Layout: CollapseIcon first, then Logo, then CloseIcon with Tooltip */}
            {/* New Collapse Button */}
            <div
              style={{ position: 'relative', display: 'inline-block', marginRight: '15px' }} // Changed marginLeft to marginRight for spacing
              onMouseEnter={() => setHoveredCollapse(true)}
              onMouseLeave={() => setHoveredCollapse(false)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '30px',
                  height: '30px',
                  borderRadius: '4px',
                  backgroundColor: hoveredCollapse ? collapseHoverBackgroundColor : 'transparent',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                  padding: '5px',
                  marginTop: '-20px',
                }}
              >
                <CollapseIcon
                  style={{
                    width: '25px',
                    height: '25px',
                    fill: textColor, // Ensures SVG uses textColor
                  }}
                />
              </div>
              {/* Custom Tooltip for CollapseIcon */}
              {hoveredCollapse && (
                <div
                  style={{
                    position: 'fixed',
                    left: '47.5px', // Adjust tooltip position as needed
                    top: '32.5px',
                    backgroundColor: backgroundColor, // Replaced with backgroundColor
                    color: textColor,
                    padding: '2.5px 5px',
                    borderRadius: '4px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Updated boxShadow for better visibility
                    whiteSpace: 'nowrap',
                    zIndex: zIndices.options + 1,
                    border: isDarkMode ? `2px solid #3c3c3e` : `2px solid ${borderColor}`,
                    fontSize: '12px',
                  }}
                >
                  Collapse
                </div>
              )}
            </div>
            <Logo
              style={{
                marginLeft: '-10px', // No margin needed as CollapseIcon handles spacing
                width: '100px',
                height: '100px',
                fill: textColor, // Changed from getBackgroundColor(primaryColor, isDarkMode)
              }}
            />
            {/* Reordered Close Button */}
            <div
              style={{ position: 'relative', display: 'inline-block', marginLeft: '15px' }} // Added marginLeft for spacing
              onMouseEnter={() => setHoveredClose(true)}
              onMouseLeave={() => setHoveredClose(false)}
              onClick={toggleNewButton} // Sets isNewButtonClicked to false
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '30px', // Reverted to original size
                  height: '30px',
                  borderRadius: '4px',
                  backgroundColor: hoveredClose ? closeHoverBackgroundColor : 'transparent',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                  marginLeft: '-10px', // Reverted to marginRight for spacing after CloseIcon
                  marginTop: '-20px',
                  padding: '5px', // Space between CloseIcon and Logo
                }}
              >
                <CloseIcon
                  style={{
                    width: '25px',
                    height: '25px',
                    fill: textColor, // Ensures SVG uses textColor
                  }}
                />
              </div>
              {/* Custom Tooltip for CloseIcon */}
              {hoveredClose && (
                <div
                  style={{
                    position: 'fixed',
                    left: '122.5px', // Adjust tooltip position as needed
                    top: '30px',
                    backgroundColor: backgroundColor, // Replaced with backgroundColor
                    color: textColor,
                    padding: '2.5px 5px',
                    borderRadius: '4px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Updated boxShadow for better visibility
                    whiteSpace: 'nowrap',
                    zIndex: zIndices.options + 1,
                    border: isDarkMode ? `2px solid #3c3c3e` : `2px solid ${borderColor}`,
                    fontSize: '12px',
                  }}
                >
                  50%
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className="button-container"
          style={{
            backgroundColor: isNewButtonClicked ? 'transparent' : backgroundColor, // Replaced with backgroundColor
            flexGrow: 1,
            overflowY: isNewButtonClicked ? 'auto' : 'visible',
            overflowX: 'hidden',
            width: isNewButtonClicked ? 'calc(100% - 20px)' : 'auto',
            paddingRight: isNewButtonClicked ? '5px' : '0',
            paddingTop: isNewButtonClicked ? '10px' : '0',
            marginLeft: isNewButtonClicked ? '15px' : '0',
            marginBottom: isNewButtonClicked ? '25px' : '0',
            outline: isNewButtonClicked ? `4px solid ${backgroundColor}` : 'none', // Replaced with backgroundColor
            scrollbarColor: `${isNewButtonClicked ? (isDarkMode ? '#3c3c3e' : '#1e1e1e') : borderColor} transparent`,
            scrollbarWidth: isNewButtonClicked ? 'auto' : 'thin',
          }}
        >
          {buttons.map((button, index) => (
            <div
              key={index}
              style={{
                position: 'relative',
                display: 'flex',
                height: 'fit-content',
                justifyContent: isNewButtonClicked ? 'left' : 'auto',
                marginBottom: isNewButtonClicked
                  ? '20px'
                  : index < buttons.length - 1
                  ? '1vh'
                  : '0',
                borderRadius: '1vh',
                padding: '2px',
                marginRight: isNewButtonClicked ? '5px' : '2px',
                paddingLeft: isNewButtonClicked ? '25px' : '2px',
                backgroundColor:
                  isNewButtonClicked && hoveredButton === index
                    ? hoverBackgroundColor
                    : 'transparent',
                transition: 'background-color 0.3s ease',
                borderRadius: isNewButtonClicked ? '4px 2.5em 2.5em 4px' : '4px',
              }}
              onMouseEnter={isNewButtonClicked ? () => setHoveredButton(index) : undefined}
              onMouseLeave={isNewButtonClicked ? () => setHoveredButton(null) : undefined}
            >
              <button
                className="down-button"
                ref={(el) => (buttonRefs.current[index] = el)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  backgroundColor:
                    !isNewButtonClicked && hoveredButton === index
                      ? hoverBackgroundColor
                      : 'transparent',
                  color: textColor, // Ensures text uses textColor
                  border: 'none',
                  padding: isNewButtonClicked ? '5px 5px' : '0.875rem 1.25rem', // 0.875rem matches Chat.js
                  marginLeft: isNewButtonClicked ? '0px' : 'none',
                  marginRight: isNewButtonClicked ? '5px' : 'none',
                  borderRadius: '1vh',
                  cursor: 'pointer',
                  width: isNewButtonClicked && maxButtonWidth ? `${maxButtonWidth}px` : '100%',
                  height: isNewButtonClicked ? '40px' : 'auto',
                  fontSize: isNewButtonClicked ? '1rem' : '0.875rem', // 0.875rem matches Chat.js
                  position: 'relative',
                  transition: 'background-color 0.3s ease, opacity 0.3s ease',
                  opacity: '1',
                  fontWeight: '500', // Corrected from 'fontweight' to 'fontWeight'
                  whiteSpace: 'nowrap', // Keeps text on one line
                  overflow: 'hidden', // Hides overflowing text
                  textOverflow: 'ellipsis', // Adds "..." when text overflows
                }}
                onMouseEnter={!isNewButtonClicked ? () => setHoveredButton(index) : undefined}
                onMouseLeave={!isNewButtonClicked ? () => setHoveredButton(null) : undefined}
              >
                <button.Icon
                  style={{
                    width: isNewButtonClicked ? '1.75rem' : '1rem', // Adjusted icon sizes
                    height: isNewButtonClicked ? '1.75rem' : '1rem',
                    marginRight: isNewButtonClicked ? '0.3125rem' : '0.25rem', // 5px and 4px respectively
                    minHeight: isNewButtonClicked ? '24px' : 'none',
                    minWidth: isNewButtonClicked ? '24px' : 'none',
                    flexShrink: 0,
                    //fill: textColor, // Ensures SVG uses textColor
                  }}
                />
                <span
                  style={{
                    marginLeft: isNewButtonClicked ? '0.3125rem' : '0.25rem', // 5px and 4px respectively
                    textAlign: 'left',
                    flexGrow: 1,
                    fontSize: 'inherit', // Inherits from parent for consistency
                  }}
                >
                  {button.label}
                </span>
              </button>
            </div>
          ))}
        </div>

        {isNewButtonClicked && (
          <div
            className="additional-container"
            style={{
              position: 'absolute',
              bottom: '0px',
              width: '100%',
              backgroundColor: velma, // Replaced with velma
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px',
              boxSizing: 'border-box',
            }}
          >
            {/* You can add content here if needed */}
          </div>
        )}
      </div>
    </>
  ) : null;
};

export default Down;
