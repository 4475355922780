import React from 'react';
import { ThemeProvider } from './ThemeContext.js';
import Options from './Options.js';
import AppContent from './AppContent.js';

const App = () => {
    return (
            <AppContent />
    );
};

export default App;