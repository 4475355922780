// BotService.js
export const fetchBotResponse = async (message) => {
  console.log("BotService.js: Initiating fetchBotResponse with message:", message);

  const hasConsent = sessionStorage.getItem('hasConsent') === 'true';
  if (!hasConsent) {
    console.warn("BotService.js: Consent not given. Need to show ConsentUI.");
    throw new Error("ConsentRequired");
  }

  try {
    const response = await fetch('/.netlify/functions/signalSessionStart', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customerId: message.customerId, userMessage: message.userMessage }),
    });

    console.log("BotService.js: Response status:", response.status);
    console.log("BotService.js: Response headers:", response.headers);

    const textData = await response.text();
    console.log("BotService.js: Raw response text:", textData);

    if (!textData) {
      console.error("BotService.js: Empty response from Netlify function.");
      throw new Error("Received an empty response from the Netlify function.");
    }

    let data;
    try {
      data = JSON.parse(textData);
    } catch (jsonParseError) {
      console.error("BotService.js: JSON parsing error:", jsonParseError);
      throw new Error("Failed to parse JSON from Netlify function response.");
    }

    // Check for 'message' or 'response' fields
    const botResponse = data.response || data.message;
    if (!botResponse) {
      console.error("BotService.js: Unexpected response structure:", data);
      throw new Error("Unexpected response structure from Netlify function.");
    }

    console.log("BotService.js: Successfully fetched bot response:", botResponse);
    return botResponse;

  } catch (error) {
    console.error("BotService.js: Failed to fetch the bot response:", error);
    throw error;
  }
};
