// ConsentUI.js
import React, { useContext, useState } from 'react';
import { ThemeContext } from './ThemeContext.js';

const ConsentUI = ({ onAgree }) => {
  const { isDarkMode, primaryColor } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  // Helper functions for colors and styling
  const getBackgroundColor = (primaryColor, isDarkMode) => {
    return isDarkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(240, 240, 245, 0.8)';
  };

  const adjustTextColor = (primaryColor) => {
    const r = parseInt(primaryColor.slice(1, 3), 16);
    const g = parseInt(primaryColor.slice(3, 5), 16);
    const b = parseInt(primaryColor.slice(5, 7), 16);
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance > 186 ? '#1E1E1E' : '#f0f0f5';
  };

  const backgroundColor = getBackgroundColor(primaryColor, isDarkMode);
  const textColor = adjustTextColor(primaryColor);

  const handleAgree = async () => {
    setLoading(true);
    console.log("ConsentUI: handleAgree triggered, sending request to verifyGeolocation");

    try {
      // Minimal request to trigger consent verification
      const response = await fetch('/.netlify/functions/verifyGeolocation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId: "ConsentingSession" }), // Simple trigger payload
      });

      console.log("ConsentUI: Received response, status:", response.status);

      if (response.ok) {
        const result = await response.json();
        console.log("ConsentUI: Parsed response JSON:", result);

        if (result.allowed) {
          console.log("ConsentUI: Consent granted, setting sessionStorage and calling onAgree");
          sessionStorage.setItem('hasConsent', 'true'); // Set consent in sessionStorage
          onAgree(); // Notify parent component of consent approval
        } else {
          console.warn("ConsentUI: Access denied by server");
          alert('Access denied: Only users from permitted locations can use this service.');
        }
      } else {
        console.error('ConsentUI: Failed to verify geolocation, response not OK');
        alert('An error occurred while verifying your location. Please try again.');
      }
    } catch (error) {
      console.error('ConsentUI: An error occurred:', error);
      alert('An error occurred while processing your consent. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark overlay
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          padding: '3vh 5vw',
          borderRadius: '10px',
          textAlign: 'center',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
          maxWidth: '90%',
          width: '400px',
        }}
      >
        <h2 style={{ marginBottom: '1em' }}>Consent Required</h2>
        <p style={{ fontSize: '1.2em', lineHeight: '1.5' }}>
          By continuing, you consent to data processing for geolocation verification.
          This data is processed to ensure compliance with local regulations.
        </p>
        <button
          onClick={handleAgree}
          disabled={loading}
          style={{
            marginTop: '1.5em',
            padding: '0.8em 2em',
            fontSize: '1em',
            fontWeight: 'bold',
            color: '#ffffff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            opacity: loading ? 0.6 : 1,
            transition: 'background-color 0.3s ease',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = adjustTextColor(primaryColor))}
          onMouseLeave={(e) => (e.target.style.backgroundColor = primaryColor)}
        >
          {loading ? 'Verifying...' : 'Agree'}
        </button>
      </div>
    </div>
  );
};

export default ConsentUI;
